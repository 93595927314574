import styles from './glp-report.module.scss';
import classNames from 'classnames';
import { formatterDate, getQueryParams, viewInArrayViews } from '../../utils/functions';
import { ArrowPathIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState, useEffect, useContext, useMemo } from 'react';
import { HorizontalBarEChart } from '../horizontal-bar-e-chart/horizontal-bar-e-chart';
import { Column, DelayCountry, GLPSales, GroupDataWithTypesProps } from '../../utils/interfaces';
import { groupData, postActionLogs, queryV1 } from '../../services/python-services';
import { queries_glp, size_page } from '../../utils/constants';
import { PortMap } from '../port-map/port-map';
import { Table } from '../table/table';
import { FilterCard } from '../filter-card/filter-card';
import { FilterButton } from '../filter-button/filter-button';
import { Spinner } from 'flowbite-react';
import { Button } from '../button/button';
import { RMContext } from '../../context/RMContext';

export interface GlpReportProps {
    name?: string;
}

export const GlpReport = ({ name }: GlpReportProps) => {
    const cntr = window.location.search.split('?cntr=');
    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 90));

    const intialDCState: DelayCountry[] = [];
    const [delayCountryData, setDelayCountryData] = useState(intialDCState);
    const [delayProductGroupData, setDelayProductGroupData] = useState([]);
    const [delayToCustomerData, setDelayToCustomerData] = useState([]);
    const [leadTimeProductGroupData, setLeadTimeProductGroupData] = useState([]);
    const [loadLeadTimeProductGroupData, setLoadLeadTimeProductGroupData] = useState(false);
    const [leadTimeFactoryData, setLeadTimeFactoryData] = useState([]);
    const [loadLeadTimeFactoryData, setLoadLeadTimeFactoryData] = useState(false);
    const [leadTimeToCustomerData, setLeadTimeToCustomerData] = useState([]);
    const [loadLeadTimeToCustomerData, setLoadLeadTimeToCustomerData] = useState(false);
    const [leadTimePortData, setLeadTimePortData] = useState([]);
    const initialTableState: any[] = [];
    const [mainTableData, setMainTableData] = useState(initialTableState);
    const [total, setTotal] = useState(0);
    const [totalDatas, setTotalDatas] = useState(0);
    const [downLoader, setDownLoader] = useState<boolean>(false);
    const [loadingMainData, setLoadingMainData] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const currentView = window.location.href.split('/').slice(-1)[0];
    const { sitemap } = useContext(RMContext).userData;

    useEffect(() => {
        const viewD = viewInArrayViews(currentView, sitemap);
        if (viewD?.hasOwnProperty('id')) {
            postActionLogs(viewD.id);
        }
    }, [sitemap]);

    //Pagination
    const [page, setPage] = useState(1);
    const [isLoadingDataScroll, setIsLoadingDataScroll] = useState(false);
    const [moreItems, setMoreItems] = useState(true);
    // FILTERS
    const [searchText, setSearchText] = useState('');
    const [customFilters, setCustomFilters] = useState<any>([]);
    const [executeApply, setExecuteApply] = useState(true);
    const [applySearch, setApplySearch] = useState(true);
    const filtersString = [
        'page',
        'size',
        'factory',
        'product-group-glp',
        'customer-glp',
        'country-glp',
        'delayed',
        'arrived',
    ];
    const [closedFilters, setClosedFilters] = useState(true);
    const {finalctry}: any = getQueryParams(window.location.search);
    const [queries, setQueries] = useState<GLPSales>({
        page: 1,
        size: size_page,
        sitefromname: null,
        divisionname: null,
        soldtoname: null,
        finalctry: finalctry!==undefined ? finalctry.toUpperCase():null,
        'delayeddays:not': null,
        delayeddays: null,
        searchGlp: null,
        sort_by: null,
        delayed: null,
        cntr_truckno: cntr.length > 1 ? '' + cntr[1] : null,
    });

    const [uniques, setUniques] = useState<GLPSales>({
        sitefromname: null,
        divisionname: null,
        soldtoname: null,
        finalctry: finalctry!==undefined ? finalctry.toUpperCase():null,
    });

    const clearValues = () => {
        setMainTableData([]);
        setTotalDatas(0);
        setPage(1);
    };

    const resetFilters = (nameComponent: string = 'filter') => {
        //clearValues();
        if (nameComponent === 'table') {
            setClosedFilters(true);
        }
        const updatedQueries = {
            page: 1,
            size: size_page,
            sitefromname: null,
            divisionname: null,
            soldtoname: null,
            finalctry: finalctry!==undefined ? finalctry.toUpperCase():null,
            'delayeddays:not': null,
            delayeddays: null,
            delayed: null,
            searchGlp: null,
            sort_by: null,
        };
        setSearchText('');
        setPage(1);
        setQueries(updatedQueries);
        setApplySearch(true);
        setExecuteApply(true);
    };

    const applyFilters = (filters: any, type: string = '', apply: boolean = false) => {
        if (type === 'reset') {
            resetFilters();
        } else {
            //clearValues();
            const updatedQueries = { ...filters, page: 1, size: size_page };
            setQueries(updatedQueries);
            setUniques(updatedQueries);
        }
        setExecuteApply(apply);
        setApplySearch(apply);
    };

    const closeFilter = (isClosed: boolean) => {
        setClosedFilters(isClosed);
    };

    const getDelayCountryData = async () => {
        const response = await queryV1(
            queries_glp.delayCountry.table,
            queries_glp.delayCountry.groupsBy,
            queries_glp.delayCountry.query,
            queries,
            'athena'
        );
        if (response) {
            const sort_data = response?.data.sort(
                (a: any, b: any) => parseInt(b.distinct_po_count) - parseInt(a.distinct_po_count)
            );
            setDelayCountryData(sort_data);
        }
    };

    const getDelayProductGroupData = async () => {
        const response = await queryV1(
            queries_glp.delayProductGroup.table,
            queries_glp.delayProductGroup.groupsBy,
            queries_glp.delayProductGroup.query,
            queries,
            'athena'
        );
        if (response) {
            const sort_data = response?.data.sort(
                (a: any, b: any) => parseInt(b.distinct_po_count) - parseInt(a.distinct_po_count)
            );
            const sum = response?.data.reduce((acc: any, val: any) => {
                return acc + parseInt(val.distinct_po_count);
            }, 0);
            setTotal(sum);
            setDelayProductGroupData(sort_data);
        }
    };

    const getDelayToCustomerData = async () => {
        const response = await queryV1(
            queries_glp.delayToCustomer.table,
            queries_glp.delayToCustomer.groupsBy,
            queries_glp.delayToCustomer.query,
            queries,
            'athena'
        );
        if (response) {
            const sort_data = response?.data.sort(
                (a: any, b: any) => parseInt(b.distinct_po_count) - parseInt(a.distinct_po_count)
            );
            setDelayToCustomerData(sort_data);
        }
    };

    const getLeadTimeProductGroupData = async () => {
        setLoadLeadTimeProductGroupData(true);
        const response = await queryV1(
            queries_glp.leadTimeProductGroup.table,
            queries_glp.leadTimeProductGroup.groupsBy,
            queries_glp.leadTimeProductGroup.query,
            queries,
            'athena'
        );
        if (response) {
            const sort_data = response?.data.sort(
                (a: any, b: any) => parseInt(a.avg_lead_time) - parseInt(b.avg_lead_time)
            );
            setLeadTimeProductGroupData(sort_data);
        }
        setLoadLeadTimeProductGroupData(false);
    };

    const getLeadTimeFactoryData = async () => {
        setLoadLeadTimeFactoryData(true);
        const response = await queryV1(
            queries_glp.leadTimeFactory.table,
            queries_glp.leadTimeFactory.groupsBy,
            queries_glp.leadTimeFactory.query,
            queries,
            'athena'
        );
        if (response) {
            const sort_data = response?.data.sort(
                (a: any, b: any) => parseInt(a.avg_lead_time) - parseInt(b.avg_lead_time)
            );
            setLeadTimeFactoryData(sort_data);
        }
        setLoadLeadTimeFactoryData(false);
    };
    const getLeadTimeToCustomerData = async () => {
        setLoadLeadTimeToCustomerData(true);
        const response = await queryV1(
            queries_glp.leadTimeToCustomer.table,
            queries_glp.leadTimeToCustomer.groupsBy,
            queries_glp.leadTimeToCustomer.query,
            queries,
            'athena'
        );
        if (response) {
            const sort_data = response?.data.sort(
                (a: any, b: any) => parseInt(a.avg_lead_time) - parseInt(b.avg_lead_time)
            );
            setLeadTimeToCustomerData(sort_data);
        }
        setLoadLeadTimeToCustomerData(false);
    };

    const getLeadTimePortData = async () => {
        const response = await queryV1(
            queries_glp.leadTimePort.table,
            queries_glp.leadTimePort.groupsBy,
            queries_glp.leadTimePort.query,
            queries,
            'athena'
        );
        if (response) {
            setLeadTimePortData(response.data.filter((q: any) => q.port !== null && q.port !== ''));
        }
    };
    const getMainTableData = async () => {
        setLoadingMainData(true);
        let newQueiries = { ...queries, page: 1, size: size_page };

        const response = await queryV1(
            queries_glp.mainTable.table,
            queries_glp.mainTable.groupsBy,
            queries_glp.mainTable.query,
            newQueiries,
            'athena'
        );
        if (response) {
            setMainTableData(response?.data);
        }
        setLoadingMainData(false);
    };
    const getTotalDatas = async () => {
        let filter_queries = { ...queries };
        //filter_queries.page = 1;
        filter_queries.sort_by = '';
        delete filter_queries.page;
        delete filter_queries.size;
        const response = await queryV1(
            queries_glp.totalMainTable.table,
            queries_glp.totalMainTable.groupsBy,
            queries_glp.totalMainTable.query,
            filter_queries,
            'athena'
        );
        if (response) {
            setTotalDatas(response?.data[0].totals);
            if (parseInt(response?.data[0].totals) <= mainTableData.length) {
                setMoreItems(false);
            } else {
                setMoreItems(true);
            }
        }
    };
    useEffect(() => {
        if (executeApply) {
            getDelayCountryData();
            getDelayProductGroupData();
            getDelayToCustomerData();
            getLeadTimeProductGroupData();
            getLeadTimeFactoryData();
            getLeadTimeToCustomerData();
            getLeadTimePortData();
            setExecuteApply(false);
        }
        if (applySearch) {
            getTotalDatas();
            getMainTableData();
            setApplySearch(false);
        }
        setDataLoaded(true);
    }, [queries]);

    const getDelayedOptions = (delayed: any[]) => {
        return delayed.map((del) => {
            return (
                <span key={del.divisionname}>
                    {del.divisionname}: {del.distinct_po_count} PO delayed
                    <br />
                </span>
            );
        });
    };
    const getDelayedTable = (delayedT: any[]) => {
        return (
            <table className={'table-responsive ' + styles.tableR}>
                <thead>
                    <tr>
                        <th scope="col" className="col-8">
                            Delay PO Warning by Sold to Customer
                        </th>
                        <th scope="col" className="col-4">
                            #PO
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {delayedT.map((del, index) => {
                        return (
                            <tr
                                key={del.soldtoname + index}
                                className={index % 2 === 0 ? styles.rowGrayLight : styles.rowWithe}
                            >
                                <td>{del.soldtoname}</td>
                                <td className={styles.textRight}>{del.distinct_po_count}</td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td>Total</td>
                        <td className={styles.textRight}>{total}</td>
                    </tr>
                </tfoot>
            </table>
        );
    };

    const convertDataToChart = (datas: any[]) => {
        return datas.map((data: object) => {
            return {
                label: Object.values(data)[0],
                value: parseFloat(parseFloat(Object.values(data)[1]).toFixed(2)),
            };
        });
    };

    const convertDataToMap = (datas: any[]) => {
        return datas.map((data: any) => {
            return {
                port: data['discharging__port'],
                value: parseFloat(data['avg_lead_time']).toFixed(2),
                lat: parseFloat(data['latitude']),
                lng: parseFloat(data['longitude']),
            };
        });
    };
    const mainColumns: Column[] = [
        {
            id: 'arrived_f',
            name: 'Arrived',
            prop: 'arrived_f',
            open: false,
            show: true,
            style: { minWidth: '110px' },
            formatting: (v: any) => {
                return (
                    <div>
                        {' '}
                        {v === 'true' ? 
                            "Yes"
                         : 
                            "No"
                        }{' '}
                    </div>
                );
            },
        },
        {
            id: 'po_no',
            name: 'PO #',
            prop: 'po_no',
            open: false,
            show: true,
            style: { minWidth: '102px' },
            formatting: (v: any) => <div>{v.toString()}</div>,
        },
        {
            id: 'divisionname',
            name: 'Product Group',
            prop: 'divisionname',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'sku',
            name: 'SKU',
            prop: 'sku',
            open: false,
            show: true,
            formatting: (v: any) => <div>{v.toString()}</div>,
            style: { minWidth: '150px' },
        },
        {
            id: 'loading_qty',
            name: 'Loading Qty',
            prop: 'loading_qty',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '102px' },
        },
        {
            id: 'cntr_truckno',
            name: 'CNTR #',
            prop: 'cntr_truckno',
            open: false,
            show: true,
            style: { minWidth: '131px' },
        },
        {
            id: 'sitefromname',
            name: 'Factory name',
            prop: 'sitefromname',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: 'shiptoname',
            name: 'Ship To',
            prop: 'shiptoname',
            open: false,
            show: true,
            style: { minWidth: '260px' },
        },
        {
            id: 'soldtoname',
            name: 'Sold To Name',
            prop: 'soldtoname',
            open: false,
            show: true,
            style: { minWidth: '260px' },
        },
        {
            id: 'departurectry',
            name: 'DEP',
            prop: 'departurectry',
            open: false,
            show: true,
            style: { minWidth: '105px' },
        },
        {
            id: 'departureport',
            name: 'Dep Port',
            prop: 'departureport',
            open: false,
            show: true,
            style: { minWidth: '159px' },
        },
        {
            id: 'dischargingctry',
            name: 'DIS',
            prop: 'dischargingctry',
            open: false,
            show: true,
            style: { minWidth: '105px' },
        },
        {
            id: 'discharging__port',
            name: 'Dis Port',
            prop: 'discharging__port',
            open: false,
            show: true,
            style: { minWidth: '130px' },
        },
        {
            id: 'finalctry',
            name: 'DES',
            prop: 'finalctry',
            open: false,
            show: true,
            style: { minWidth: '101px' },
        },
        {
            id: 'finalport',
            name: 'Des Port',
            prop: 'finalport',
            open: false,
            show: true,
            style: { minWidth: '170px' },
        },
        {
            id: 'incoterms1',
            name: 'Inco Terms',
            prop: 'incoterms1',
            open: false,
            show: true,
            style: { minWidth: '102px' },
        },
        {
            id: 'shipping__carrier__grouping',
            name: 'Shipping',
            prop: 'shipping__carrier__grouping',
            open: false,
            show: true,
            style: { minWidth: '210px' },
        },
        {
            id: 'forecastlt',
            name: 'Forecast LT',
            prop: 'forecastlt',
            open: false,
            show: false,
            cellType: 'pl.Int64',
            style: { minWidth: '102px' },
        },
        {
            id: 'historicleadtime',
            name: 'Lead Time',
            prop: 'historicleadtime',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '102px', marginLeft: '1px', borderLeft: '1px solid #000' },
        },
        // {
        //     id: 'lt',
        //     name: 'Contract L/T',
        //     prop: 'lt',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Int64',
        //     style: { minWidth: '80px' },
        // },

        // {
        //     id: 'actuallt',
        //     name: 'Actual LT',
        //     prop: 'actuallt',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Int64',
        //     style: { minWidth: '90px' },
        // },

        {
            id: 'contract_lty23',
            name: 'LT Y23',
            prop: 'contract_lty23',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '102px' },
            formatting: (v: any) => {
                return `${v !== null ? parseFloat(v).toFixed(0) : ''}`;
            },
        },
        {
            id: 'gap',
            name: 'Gap',
            prop: 'gap',
            open: false,
            show: true,
            formatting: (v: any) => {
                return (
                    <div
                        style={{
                            color: v <= 0 ? '#137fc4' : '#a4282c',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginRight: '2px',
                        }}
                    >
                        <strong>
                            {v !== null && typeof v === 'number'
                                ? v.toFixed(0)
                                : typeof v === 'string'
                                    ? parseFloat(v).toFixed(0)
                                    : v}
                        </strong>
                    </div>
                );
            },
            cellType: 'pl.Int64',
            style: { minWidth: '102px', marginRight: '1px', borderRight: '1px solid #000' },
        },
        {
            id: 'port_atddate',
            name: 'Dep Date',
            prop: 'port_atddate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '103px' },
        },
        {
            id: 'port_etadate',
            name: 'Eta Date',
            prop: 'port_etadate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '103px' },
        },
        {
            id: 'port_atadate',
            name: 'Ata Date',
            prop: 'port_atadate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '103px' },
        },
        {
            id: 'intransitdate',
            name: 'In Transit Date',
            prop: 'intransitdate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '115px' },
        },
        {
            id: 'port_1sttransit_crty',
            name: 'Port 1sttransit Crty',
            prop: 'port_1sttransit_crty',
            open: false,
            show: true,
            style: { minWidth: '130px' },
        },
        {
            id: 'port_1sttransit_port',
            name: '1sttransit Port',
            prop: 'port_1sttransit_port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: '1st_transit_dateata',
            name: '1st Transit Dateata',
            prop: '1st_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '130px' },
        },
        {
            id: '1st_transit_dateatd',
            name: '1st Transit Date Atd',
            prop: '1st_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '160px' },
        },
        {
            id: 'port_2ndtransit_crty',
            name: 'Port 2ndtransit Crty',
            prop: 'port_2ndtransit_crty',
            open: false,
            show: true,
            style: { minWidth: '140px' },
        },
        {
            id: 'port_2ndtransit_port',
            name: '2nd Transit Port',
            prop: 'port_2ndtransit_port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: '2nd_transit_dateata',
            name: '2nd Transit Date Ata',
            prop: '2nd_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '140px' },
        },
        {
            id: '2nd_transit_dateatd',
            name: '2nd Transit Date Atd',
            prop: '2nd_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '140px' },
        },
        {
            id: 'port_3rdtransit_crty',
            name: 'Port 3rdtransit Crty',
            prop: 'port_3rdtransit_crty',
            open: false,
            show: true,
            style: { minWidth: '140px' },
        },
        {
            id: 'port_3rdtransit_port',
            name: '3rdtransit Port',
            prop: 'port_3rdtransit_port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: '3rd_transit_dateata',
            name: '3rd Transit Date Ata',
            prop: '3rd_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '140px' },
        },
        {
            id: '3rd_transit_dateatd',
            name: '3rd_transit Date Atd',
            prop: '3rd_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '140px' },
        },
        {
            id: 'port_4thtransit_crty',
            name: 'Port 4thtransit Crty',
            prop: 'port_4thtransit_crty',
            open: false,
            show: true,
            style: { minWidth: '140px' },
        },
        {
            id: 'port_4thtransit_port',
            name: '4thtransit Port',
            prop: 'port_4thtransit_port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: '4th_transit_dateata',
            name: '4th Transit Date Ata',
            prop: '4th_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '140px' },
        },
        {
            id: '4th_transit_dateatd',
            name: '4th Transit Date Atd',
            prop: '4th_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '140px' },
        },
    ];

    const reduceColumns = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (column.show === true) {
                result[column.id] = column.name;
            }
            return result;
        }, {});
        return res;
    };
    const reduceColumnTypes = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (column.show === true && column.cellType !== undefined && column.cellType !== null) {
                result[column.id] = column.cellType;
            }
            return result;
        }, {});
        return res;
    };

    const exportExcel = async (columns: any) => {
        setDownLoader(true);
        const col = reduceColumns(columns);
        const column_types = reduceColumnTypes(columns);
        const body: any = queries_glp.mainTable.query;
        delete body.data[0].offset;
        delete body.data[0].limit;

        const payloadGroupData: GroupDataWithTypesProps = {
            queries: [
                {
                    service: 'queryV1',
                    kwargs: {
                        groups: queries_glp.mainTable.groupsBy,
                        name: queries_glp.mainTable.table,
                    },
                    body: queries_glp.mainTable.query,
                },
            ],
            export: {
                columns: col,
                column_types: column_types,
                format: 'xlsx',
            },
        };
        const export_query = { ...queries };
        delete export_query.sort_by;
        delete export_query.page;
        delete export_query.size;
        const res = await groupData(payloadGroupData, export_query, 'athena');
        window.open(res['url']);
        setDownLoader(false);
    };
    const updateData = (newData: any) => {
        setMainTableData([]);
        setMainTableData(newData);
    };

    const fetchData = async (isScroll: boolean = false) => {
        // avoid multiple call to the fetchData
        if (loadingMainData) {
            return;
        }
        const updatedQueries = { ...queries, page: page + 1, size: size_page };
        setQueries(updatedQueries);
        setIsLoadingDataScroll(isScroll);
        setLoadingMainData(true); // Establecer isLoadingData a true antes de la solicitud

        try {
            // make and run request
            const response = await queryV1(
                queries_glp.mainTable.table,
                queries_glp.mainTable.groupsBy,
                queries_glp.mainTable.query,
                updatedQueries,
                'athena'
            );
            // update data and state variables
            const newData: any[] = [...mainTableData, ...response.data];
            setMainTableData(isScroll ? newData : response.data);
            if (newData.length >= totalDatas) {
                setMoreItems(false);
            } else {
                setPage((prev) => prev + 1);
            }
            setExecuteApply(false);
            setApplySearch(false);

            // Reset loading states
            setLoadingMainData(false);
            setIsLoadingDataScroll(false);
        } catch (error) {
            // Reset loading states
            setIsLoadingDataScroll(false);
            setLoadingMainData(false);
        }
    };
    const searchData = (type: any) => {
        setApplySearch(true);
        setLoadingMainData(true);
        //remove spaces in the search type
        type = type.replace(/\s+/g, ' ').trim();
        setSearchText(type);
        if (type === '') {
            clearValues();
            setQueries({ ...queries, searchGlp: null });
        } else if (type.length > 2) {
            clearValues();
            setQueries({ ...queries, searchGlp: type.toUpperCase() });
        }
    };
    const sortData = (type: any) => {
        setApplySearch(true);
        setPage(1);
        let newQueiries: any = { ...queries, page: 1, size: size_page * 1 };
        clearValues();
        if (type.name === 'Descending') {
            newQueiries.sort_by = type.prop + ',desc';
        } else {
            newQueiries.sort_by = type.prop;
        }
        setQueries(newQueiries);
    };

    const leadTimeProductGroupDataMemoized = useMemo(
        () => (
            <div>
                {!loadLeadTimeProductGroupData ? (
                    <div className={styles['chartDPG']} id="chartDPG">
                        <HorizontalBarEChart
                            className={'chartDPG'}
                            title="L/T Per Product Group"
                            data_chart={convertDataToChart(leadTimeProductGroupData)}
                        />
                    </div>
                ) : (
                    <Spinner size="sm" />
                )}
            </div>
        ),
        [loadLeadTimeProductGroupData, leadTimeProductGroupData]
    );

    const leadTimeFactoryDataMemoized = useMemo(
        () => (
            <>
                {!loadLeadTimeFactoryData ? (
                    <div className={styles['chartF']} id="chartF">
                        <HorizontalBarEChart
                            className={'chartF'}
                            title="L/T Per Factory (AVG Days)"
                            data_chart={convertDataToChart(leadTimeFactoryData)}
                        />
                    </div>
                ) : (
                    <Spinner size="sm" />
                )}
            </>
        ),
        [leadTimeFactoryData, loadLeadTimeFactoryData]
    );

    const leadTimeToCustomerDataMemoized = useMemo(
        () => (
            <>
                {!loadLeadTimeToCustomerData ? (
                    <div className={styles['chartSC']} id="chartSC">
                        <HorizontalBarEChart
                            className={'chartSC'}
                            title="L/T Per Sold to Customer (AVG Days)"
                            data_chart={convertDataToChart(leadTimeToCustomerData)}
                        />
                    </div>
                ) : (
                    <Spinner size="sm" />
                )}
            </>
        ),
        [leadTimeToCustomerData, loadLeadTimeToCustomerData]
    );

    return (
        <div className={classNames(styles.root) + ' flex h-[98vh] w-screen ' + name}>
            {!closedFilters && (
                <FilterCard
                    close={closeFilter}
                    applyFilters={applyFilters}
                    columns={mainColumns}
                    setUniques={setUniques}
                    filters={filtersString}
                    file={'glp-shipmentoptimized'}
                    uniques={uniques}
                    by="athena"
                    onReset={clearValues}
                    setExecuteApply={setExecuteApply}
                    setApplySearch={setApplySearch}
                    setCustomFilters={setCustomFilters}
                    customFilters={customFilters}
                    isColapsed={true}
                />
            )}
            <div className={closedFilters ? 'w-full' : 'w-[80%]'}>
                {dataLoaded && (
                    <div className={styles.root}>
                        <div className={styles.upper}>
                            <div className={styles.headerReport}>
                            <span className={styles.descriptionCountry}>{finalctry!==undefined?finalctry?.toUpperCase():'SELA'}</span>
                                <span className={styles.tittleReport}>
                                    Logistic Leadtime Tracking System: Sales Version
                                </span>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '7px'
                            }}>
                                {closedFilters && (
                                    <div className={styles.filterButton}>
                                        <FilterButton
                                            className="hidden"
                                            close={closeFilter}
                                            top={154}
                                            regularFormat={true}
                                        />
                                    </div>
                                )}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'end'
                                    }}
                                >
                                    <div className={styles.textReport}>
                                        <span>
                                            Period: {formatterDate(priorDate)} to {formatterDate(today)}
                                        </span>
                                    </div>
                                    <div className={styles.textUpdateReport}>
                                        <span>
                                            Update by{' '}GLP Outbound Tracking View at{' '}{formatterDate(today)}
                                                {/* <span className={styles.refresh}>
                                                <Button
                                                    click={() => { }}
                                                    styleProp=" rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray  gap-1 items-center h-100 min-w-[100px]"
                                                >
                                                    <ArrowPathIcon className={'h-6 w-6 '} /><span className={styles.buttonLabel}> Refresh</span>
                                                </Button>
                                            </span> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.intermediate}>
                            <div className={styles.section1}>
                                <div className={styles.textReportTable}>
                                    <p style={{ marginTop: '2px' }}>
                                        Delay PO Warning by Product Group
                                        <br />
                                        <br />
                                        {getDelayedOptions(delayProductGroupData)}
                                    </p>
                                </div>
                                <div className={styles.textReportTableDT}>
                                    {getDelayedTable(delayToCustomerData)}
                                </div>
                            </div>
                            <div className={styles.section2}>
                                <div
                                    className={styles.textReportTableC}
                                    style={{ fontSize: window.screen.width < 1370 ? 8.8 : 11 }}
                                >
                                    <span>
                                        Delay PO Warning by County
                                        <br />
                                        {delayCountryData &&
                                            delayCountryData.length > 0 &&
                                            delayCountryData.map(
                                                (delay: DelayCountry, index: number) => {
                                                    return (
                                                        <span key={'DC' + index}>
                                                            {delay.arrival__group}{' '}
                                                            {delay.distinct_po_count}{' '}
                                                            {index !== delayCountryData.length - 1
                                                                ? ' | '
                                                                : ''}
                                                        </span>
                                                    );
                                                }
                                            )}
                                    </span>
                                </div>
                                {leadTimeProductGroupDataMemoized}
                            </div>
                            <div className={styles.section3}>{leadTimeFactoryDataMemoized}</div>
                            <div className={styles.section4}>{leadTimeToCustomerDataMemoized}</div>
                            <div className={styles.section5}>
                                {leadTimePortData.length > 0 ? (
                                    <div className={styles['chartMap']}>
                                        <PortMap
                                            className="glpMap"
                                            title="L/T Per Region"
                                            /* data_chart={{
                                                data: [],
                                                geoCoorMap: convertDataToMap(leadTimePortData),
                                            }} */
                                            lead_time={leadTimePortData}
                                            center_map={{ lat: 15.0, lng: -73.0 }}
                                            zoom={3.1}
                                        />
                                    </div>
                                ) : (
                                    <Spinner size="sm" />
                                )}
                            </div>
                        </div>
                        <div>
                            <div className={styles['mainTable']}>
                                <Table
                                    isSearch={true}
                                    searchFunction={searchData}
                                    searchText={searchText}
                                    sortFunction={sortData}
                                    columnsButton={true}
                                    resetButton={true}
                                    columns={mainColumns}
                                    data={mainTableData}
                                    count={totalDatas}
                                    exportLoader={downLoader}
                                    fetchData={fetchData}
                                    title=""
                                    exportData={exportExcel}
                                    haveMoreItems={moreItems}
                                    updateData={updateData}
                                    isLoadingData={loadingMainData}
                                    isLoadingDataScroll={isLoadingDataScroll}
                                    allColumnsStyle=" max-w-[200px] "
                                    activeCompactMode={true}
                                    onReset={resetFilters}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
