import React, { useEffect, useState, useRef } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import styles from './email-add-button.module.scss';

type EmailAddButtonProps = {
    onClickButton: (e: any) => void;
    onHandleEmail: (e: any) => void;
    emailValue: string;
};

export const EmailAddButton = ({
    onClickButton,
    onHandleEmail,
    emailValue,
}: EmailAddButtonProps) => {
    const [openInput, setOpenInput] = useState(false);
    const inputRef = useRef<HTMLDivElement>(null);

    const addEmail = () => {
        setOpenInput(!openInput);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onClickButton(e);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                setOpenInput(false);
            }
        };

        if (openInput) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openInput]);

    return (
        <div>
            {!openInput ? (
                <div className={styles.PlusIcon}>
                    <PlusIcon className="w-4 h-4" color="#3b3b3b" onClick={addEmail} />
                </div>
            ) : (
                <div className={styles.main} ref={inputRef}>
                    <div>
                        <input
                            type="text"
                            placeholder="Type new recipient"
                            value={emailValue}
                            onChange={onHandleEmail}
                            className={styles.input}
                            onKeyDown={handleKeyPress}
                        />
                    </div>
                    <div className={styles.addButton} onClick={onClickButton}>
                        Add
                    </div>
                </div>
            )}
        </div>
    );
};
