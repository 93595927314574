import React, { useState, useRef, useEffect } from 'react';
import styles from './multi-select-dropdown.module.scss';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Notiflix from 'notiflix';

export interface MultiSelectProps {
    icon?: any;
    options?: any;
    chooseLegend?: any;
    onChange?: (selected: any[]) => void;
    reset?: boolean;
    filtersOpened?: any;
    setFiltersOpened?: any;
    name?: string;
}

export const MultiSelectDropdown = ({
    icon,
    options,
    chooseLegend,
    onChange,
    reset,
    filtersOpened = null,
    setFiltersOpened = null,
    name = '',
}: MultiSelectProps) => {
    // console.log("filtersOpened", filtersOpened, options)
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedData, setSelectedData] = useState<any[]>(['All']);
    const [filterText, setFilterText] = useState('');
    const dropdownRef = useRef<HTMLDivElement>(null);

    const customSort = (a: any, b: any) => {
        // console.log("customSort", typeof (a), a, b)
        if (typeof (a) !== "object") {
            if (a === 'DEFAULT') return 1;
            if (b === 'DEFAULT') return -1;

            if (a.toLowerCase() === a && b.toLowerCase() !== b) return -1;
            if (a.toLowerCase() !== a && b.toLowerCase() === b) return 1;

            return a.localeCompare(b);
        } else {
            if (a.label === 'DEFAULT') return 1;
            if (b.label === 'DEFAULT') return -1;

            if (a.label.toLowerCase() === a && b.label.toLowerCase() !== b) return -1;
            if (a.label.toLowerCase() !== a && b.label.toLowerCase() === b) return 1;

            return a.label.localeCompare(b);
        }

    };

    const sortedOptions = [...options].sort(customSort);

    const manageMenuState = (e:any) => {
        // console.log('manageMenuState', openMenu, e, e.target.id, filtersOpened);
        if (filtersOpened && e.target.id !=='filterText') {
            let opeF = [...filtersOpened, name];
            setFiltersOpened(opeF);
        }
        if (e.target.id !== 'filterText') {
            setOpenMenu((value) => !value);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setOpenMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setFilterText('');
    }, [openMenu]);

    useEffect(() => {
        if (reset) {
            setSelectedData(['All']);
        }
    }, [reset]);

    const addSelectedValue = (option: any) => {
        let applyChanges = true;
        setSelectedData((prevSelectedData:any) => {
            let newSelectedData;
            // console.log(typeof(option))
            if (typeof (option) !== 'object') {
                if (option === 'All') {
                    if (prevSelectedData.includes('All')) {
                        newSelectedData = [...sortedOptions];
                    } else {
                        newSelectedData = ['All'];
                    }
                } else {

                    if (prevSelectedData.includes(option)) {
                        newSelectedData = prevSelectedData.filter((item:any) => item !== option);
                        if (newSelectedData.length === sortedOptions.length) {
                            newSelectedData = ['All', ...sortedOptions];
                        }
                        if (newSelectedData.length === 0) {
                            newSelectedData = ['All'];
                        }
                    } else {
                        newSelectedData = prevSelectedData
                            .filter((item:any) => item !== 'All')
                            .concat(option);
                        if (newSelectedData.length === sortedOptions.length) {
                            newSelectedData = ['All', ...sortedOptions];
                        }
                    }
                }

            } else {
                if (option?.label === 'All') {
                    if (prevSelectedData.includes('All')) {
                        // newSelectedData = [...sortedOptions];
                        Notiflix.Notify.failure("Please select an option!");
                        applyChanges=false;
                    } else {
                        newSelectedData = ['All'];
                    }
                } else {

                    if (prevSelectedData.includes(option?.label)) {
                        newSelectedData = prevSelectedData.filter((item:any) => item !== option?.label);
                        if (newSelectedData.length === sortedOptions.length) {
                            newSelectedData = ['All', ...sortedOptions];
                        }
                        if (newSelectedData.length === 0) {
                            newSelectedData = ['All'];
                        }
                    } else {
                        newSelectedData = prevSelectedData
                            .filter((item:any) => item !== 'All')
                            .concat(option?.label);
                        if (newSelectedData.length === sortedOptions.length) {
                            newSelectedData = ['All', ...sortedOptions];
                        }
                    }
                }
            }
            if (applyChanges) {
                onChange && onChange(newSelectedData||[]);
                return newSelectedData;
            }else return [];
            
        });
    };

    const filteredOptions = sortedOptions.filter((option) => {
        if (typeof (option) !== 'object') return option.toLowerCase().includes(filterText.toLowerCase());
        else return option?.label.toLowerCase().includes(filterText.toLowerCase())
    }
    );

    return (
        <div ref={dropdownRef} style={{ position: 'relative' }}>
            <div className={name !== '' ? styles.multiple : styles.selectDropdown} onClick={(e: any) => manageMenuState(e)}>
                <div className={styles["leftDropdown" + name] || styles.leftDropdown}>
                    <div>{icon}</div>
                    {openMenu ? (
                        <div className={name !== '' ? styles.dropdownLegendMultiple : styles.dropdownLegend}>
                            <input
                                id='filterText'
                                type="text"
                                value={filterText}
                                onChange={(e: any) => setFilterText(e.target.value)}
                                placeholder="Type to search..."
                                className={styles.searchInput}
                            />
                        </div>
                    ) : (
                        <div className={name !== '' ? styles.dropdownLegendMultiple : styles.dropdownLegend} title={selectedData?.includes('All') ? chooseLegend : selectedData.join(', ')}>
                            {/* {selectedData.includes('All') ? chooseLegend : typeof (selectedData) !== 'object' ? selectedData?.join(', ') : selectedData.map((a)=>a.label).join(', ')} */}
                            {selectedData.includes('All') ? chooseLegend : selectedData.join(', ')}
                            {/* {console.log(typeof (selectedData), "selectedData", selectedData)} */}
                        </div>
                    )}
                </div>
                <div className={name !== '' ? styles.icon : ''}>
                    <ChevronDownIcon className="w-4 h-4" />
                </div>
            </div>
            {openMenu && (
                <div className={name !== '' ? styles["dropdownMenuMultiple"] : styles.dropdownMenu}>
                    <div className={styles.option} onClick={() => (sortedOptions.length > 0 && typeof (sortedOptions[0])!=='object') ? addSelectedValue('All') : addSelectedValue({ label: 'All' })}>
                        <input
                            type="checkbox"
                            id="All"
                            style={{ cursor: 'pointer' }}
                            checked={selectedData?.includes('All')}
                            onChange={() => { }}
                        />
                        <label htmlFor="All" className={styles.countryFonts}>
                            All
                        </label>
                    </div>
                    {filteredOptions && filteredOptions.length > 0 && filteredOptions.map((option: any, index: number) => (
                        <div
                            key={index}
                            className={styles.option}
                            onClick={() => addSelectedValue(option)}
                        >
                            <input
                                type="checkbox"
                                id={typeof (option) !== 'object' ? option : option?.label}
                                style={{ cursor: 'pointer' }}
                                checked={selectedData.includes(typeof (option) !== 'object' ? option : option?.label)}
                            />
                            <label htmlFor={typeof (option) !== 'object' ? option : option?.label} className={typeof (option) !== 'object' ? styles.countryFonts : styles.optionFonts}>
                                {typeof (option) !== 'object' ? option : option?.label}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
