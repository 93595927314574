import { useEffect, useMemo, useState } from 'react';
import { query_glp_alerts_visibility, size_page } from '../../utils/constants';
import styles from './glp-alert-view.module.scss';
import classNames from 'classnames';
import { Column, GLPSales, GroupDataWithTypesProps } from '../../utils/interfaces';
import { groupData, queryV1, reSendAlertEmail } from '../../services/python-services';
import { Table } from '../table/table';
import Notiflix from 'notiflix';
import { isDateLessThanTowDays } from '../../utils/functions';

export interface GlpAlertViewProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const GlpAlertView = ({ className }: GlpAlertViewProps) => {
    const [dataLoaded, setDataLoaded] = useState(false);
    //Pagination
    const [page, setPage] = useState(1);
    const [isLoadingDataScroll, setIsLoadingDataScroll] = useState(false);
    const [moreItems, setMoreItems] = useState(false);
    const [applySearch, setApplySearch] = useState(true);
    const [queries, setQueries] = useState<GLPSales>({
        page: 1,
        size: size_page,
        sitefromname: null,
        divisionname: null,
        soldtoname: null,
        finalctry: null,
        'delayeddays:not': null,
        delayeddays: null,
        searchGlpAlert: null,
        sort_by: null,
        delayed: null,
        arrived: null,
        // cntr_truckno: cntr.length>1?""+cntr[1]:null
    });
    const [loadingMainData, setLoadingMainData] = useState(false);
    const [sortBy, setSortBy] = useState('');
    const initialTableState: any[] = [];
    const [mainTableData, setMainTableData] = useState(initialTableState);
    const [totalDatas, setTotalDatas] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [downLoaderMain, setDownLoaderMain] = useState<boolean>(false);
    // const [executeApply, setExecuteApply] = useState(true);
    const [lastRefresh, setLastRefresh] = useState('');

    const convertAlertRow = (row: any, index:number)=>{
        return {...row,index: index,
            id_arrival: (row.notice_date_arrival ? isDateLessThanTowDays(row.notice_date_arrival)===true ? row.id_arrival:null:null ),
            id_delay: (row.notice_date_delay ? isDateLessThanTowDays(row.notice_date_delay)===true ? row.id_delay:null:null ),
            id_pre_alert: (row.notice_date_pre_alert ? isDateLessThanTowDays(row.notice_date_pre_alert)===true ? row.id_pre_alert:null:null )
        };
    }
    const getMainTableData = async () => {
        setLoadingMainData(true);
        let newQueiries = {
            ...queries,
            page: 1,
            size: size_page,
            sort_by: sortBy !== '' ? sortBy : 'arrived',
        };
        const response = await queryV1(
            query_glp_alerts_visibility.mainTable.table,
            query_glp_alerts_visibility.mainTable.groupsBy,
            query_glp_alerts_visibility.mainTable.query,
            newQueiries,
            'athena'
        );
        if (response && response.data) {
            //add position in each row
            const newData = response.data?.map((row: any, index: number) => {
                const newRow = convertAlertRow(row, index);
                return { ...newRow };
            });
            setMainTableData(newData);
            if (response.data.length > 0) {
                let date: any = response.data[0]?.file_date.split('-');
                setLastRefresh(`${date[0]}-${date[1]}-${date[2]}`);
            }
        }
        setLoadingMainData(false);
    };
    const getTotalDatas = async () => {
        let filter_queries = { ...queries };
        filter_queries.sort_by = '';
        delete filter_queries.page;
        delete filter_queries.size;
        const response = await queryV1(
            query_glp_alerts_visibility.totalMainTable.table,
            query_glp_alerts_visibility.totalMainTable.groupsBy,
            query_glp_alerts_visibility.totalMainTable.query,
            filter_queries,
            'athena'
        );
        if (response) {
            setTotalDatas(response?.data[0].totals);
            if (
                parseInt(response?.data[0].totals) <= mainTableData.length ||
                parseInt(response?.data[0].totals) <= size_page
            ) {
                setMoreItems(false);
            } else {
                setMoreItems(true);
            }
        }
    };
    useEffect(() => {
        if (applySearch) {
            //console.log("only one time")
            getMainTableData();
            getTotalDatas();
            setApplySearch(false);
        }
        if (dataLoaded === false) {
            //console.log("norepeat")
            setDataLoaded(true);
        }
    }, [queries]);

    const resetFilters = (nameComponent: string = 'filter') => {
        //clearValues();
        if (nameComponent === 'table') {
            // setClosedFilters(true);
        }
        const updatedQueries = {
            page: 1,
            size: size_page,
            sitefromname: null,
            divisionname: null,
            soldtoname: null,
            finalctry: null,
            'delayeddays:not': null,
            delayeddays: null,
            searchGlpAlert: null,
            sort_by: null,
            delayed: null,
            arrived: null,
        };
        setSearchText('');
        setApplySearch(true);
        // setExecuteApply(true);
        setPage(1);
        setQueries(updatedQueries);
    };
    const searchData = (type: any) => {
        setApplySearch(true);
        type = type.replace(/\s+/g, ' ').trim();
        setSearchText(type);
        if (type === '') {
            //clearValues();
            setQueries({ ...queries, searchGlpAlert: null });
        } else if (type.length > 2) {
            //clearValues();
            setQueries({ ...queries, searchGlpAlert: type.toUpperCase() });
        }
    };
    const sortData = (type: any) => {
        setApplySearch(true);
        setPage(1);
        if (type.name === 'Descending') {
            //clearValues();
            setSortBy(`${type.prop},desc`);
            setQueries({
                ...queries,
                page: 1,
                size: size_page * 1,
                sort_by: `${type.prop},desc`,
            });
        } else {
            //clearValues();
            setSortBy(`${type.prop}`);
            setQueries({ ...queries, page: 1, size: size_page * 1, sort_by: `${type.prop}` });
        }
        //setPage(1);
    };

    const fetchData = async (isScroll: boolean = false) => {
        // avoid multiple call to the fetchData
        if (isScroll) {
            // console.log('Fetching data', queries, sortBy, loadingMainData, isLoadingDataScroll, isScroll, "hasmoreData", moreItems);
            if (loadingMainData) {
                return;
            }

            const updatedQueries = {
                ...queries,
                page: page + 1,
                size: size_page,
                sort_by: sortBy !== '' ? sortBy : 'arrived',
            };
            setQueries(updatedQueries);
            setIsLoadingDataScroll(isScroll);
            setLoadingMainData(true); // Establecer isLoadingData a true antes de la solicitud

            try {
                // make and run request
                const response = await queryV1(
                    query_glp_alerts_visibility.mainTable.table,
                    query_glp_alerts_visibility.mainTable.groupsBy,
                    query_glp_alerts_visibility.mainTable.query,
                    updatedQueries,
                    'athena'
                );
                // update data and state variables
                const newDataToAdd = response.data?.map((row: any, index: number) => {
                    // return { ...row, index: index + page * size_page };
                    return convertAlertRow(row, (index + page * size_page) );
                });
                const newData: any[] = [...mainTableData, ...newDataToAdd];
                // console.log("newData in Fetch",newData)
                setMainTableData(isScroll ? newData : response.data);
                if (newData.length >= totalDatas) {
                    setMoreItems(false);
                } else {
                    setPage((prev) => prev + 1);
                }
                // setExecuteApply(false);
                setApplySearch(false);

                // Reset loading states
                setLoadingMainData(false);
                setIsLoadingDataScroll(false);
            } catch (error) {
                // Reset loading states
                setIsLoadingDataScroll(false);
                setLoadingMainData(false);
            }
        }
    };
    const showResendButton = () =>{
        return mainTableData.map((row: any, index: number) => {
            return (
                <tr key={index}>
                    <td className="text-center">
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => resendAlert(row)}
                        >
                            Resend
                        </button>
                    </td>
                </tr>
            );
        });
    }
    const mainColumns: Column[] = [
        {
            id: 'shiptoname',
            name: 'SHIP TO NAME',
            prop: 'shiptoname',
            open: false,
            show: true,
            style: { minWidth: '260px' },
        },
        {
            id: 'arrived',
            name: 'ARRIVED',
            prop: 'arrived',
            open: false,
            show: true,
            style: { minWidth: '70px' },
            cellType: 'pl.Utf8',
            formatting: (v: any) => {
                return <div> {v === 'true' ? 'YES' : 'NO'} </div>;
            },
        },
        {
            id: 'po_no',
            name: 'PO #',
            prop: 'po_no',
            open: false,
            show: true,
            formatting: (v: any) => <div>{v.toString()}</div>,
            style: { minWidth: '100px' },
        },
        // {
        //     id: 'sku',
        //     name: 'SKU',
        //     prop: 'sku',
        //     open: false,
        //     show: true,
        //     formatting: (v: any) => <div>{v.toString()}</div>,
        //     style: { minWidth: '150px' },
        // },
        // {
        //     id: 'master_bl',
        //     name: 'BL #',
        //     prop: 'master_bl',
        //     open: false,
        //     show: true,
        //     style: { minWidth: '160px' },
        // },
        {
            id: 'cntr_no',
            name: 'CNTR #',
            prop: 'cntr_no',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'atd',
            name: 'ATD',
            prop: 'atd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'atd_rpa_date',
            name: 'ATD RPA DATE',
            prop: 'atd_rpa_date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'eta',
            name: 'ETA',
            prop: 'eta',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'ata',
            name: 'ATA',
            prop: 'ata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'ata_rpa_date',
            name: 'ATA RPA DATE',
            prop: 'ata_rpa_date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'loading_qty',
            name: 'Total',
            prop: 'loading_qty',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '80px' },
        },
        {
            id: 'min_file_date',
            name: 'FIRST RPA DATE',
            prop: 'min_file_date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'file_date',
            name: 'LAST RPA DATE',
            prop: 'file_date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'delayed_days',
            name: 'DAYS DELAYED',
            prop: 'delayed_days',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '100px' },
        },
        {
            id: 'notice_date_pre_alert',
            name: 'PRE ALERT DATE',
            prop: 'notice_date_pre_alert',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'notice_date_delay',
            name: 'DELAY DATE',
            prop: 'notice_date_delay',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'notice_date_arrival',
            name: 'ARRIVAL DATE',
            prop: 'notice_date_arrival',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate_0',
            name: 'ETA #0',
            prop: 'port_etadate_0',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate_1',
            name: 'ETA #1',
            prop: 'port_etadate_1',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate_2',
            name: 'ETA #2',
            prop: 'port_etadate_2',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate_3',
            name: 'ETA #3',
            prop: 'port_etadate_3',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate_4',
            name: 'ETA #4',
            prop: 'port_etadate_4',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate_5',
            name: 'ETA #5',
            prop: 'port_etadate_5',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate_6',
            name: 'ETA #6',
            prop: 'port_etadate_6',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate_7',
            name: 'ETA #7',
            prop: 'port_etadate_7',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate_8',
            name: 'ETA #8',
            prop: 'port_etadate_8',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate_9',
            name: 'ETA #9',
            prop: 'port_etadate_9',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },

        {
            id: 'port_etadate',
            name: 'ACTUAL ETA',
            prop: 'port_etadate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'id_pre_alert',
            name: 'Pre Alert',
            prop: 'id_pre_alert',
            open: false,
            show: true,
            style: { minWidth: '120px' },
            formatting: (v: any) => (
                <>
                    {v !== null && (
                        <button
                            className="btn btn-success"
                            onClick={() => resendAlert(parseInt(v))}
                        >
                            <strong>Resend</strong>
                        </button>
                    )}
                </>
            ),
        },
        {
            id: 'id_delay',
            name: 'Delay Notice',
            prop: 'id_delay',
            open: false,
            show: true,
            style: { minWidth: '120px' },
            formatting: (v: any) => (
                <>
                    {v !== null && (
                        <button
                            className="btn btn-success"
                            onClick={() => resendAlert(parseInt(v))}
                        >
                            <strong>Resend</strong>
                        </button>
                    )}
                </>
            ),
        },
        {
            id: 'id_arrival',
            name: 'Arrival Notice',
            prop: 'id_arrival',
            open: false,
            show: true,
            style: { minWidth: '120px' },
            formatting: (v: any) => (
                <>
                    {v !== null && (
                        <button
                            className="btn btn-success"
                            onClick={() => resendAlert(parseInt(v))}
                        >
                            <strong>Arrival Notice</strong>
                        </button>
                    )}
                </>
            ),
        },
    ];

    const resendAlert = async (id: any) => {
        // const selectedAlert = mainTableData.find((d: any) => d.id === parseInt(id));
        const response = await reSendAlertEmail(id);
        // console.log('response', response);
        if (response.message) {
            Notiflix.Notify.success(response?.message + ': ' + response?.emails);
        }
        //call the service to send the alert by email.
    };
    const exportExcelMain = async (columns: any) => {
        setDownLoaderMain(true);
        try {
            await exportExcel(columns);
        } catch (e) {
            console.log('Error exporting Data', e);
        }
        setDownLoaderMain(false);
    };
    const reduceColumns = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (column.show === true && !column.name.includes('Details')) {
                result[column.id] = column.name;
            }
            return result;
        }, {});
        return res;
    };

    const reduceColumnTypes = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (
                column.show === true &&
                column.cellType !== undefined &&
                column.cellType !== null &&
                !column.name.includes('Details')
            ) {
                result[column.id] = column.cellType;
            }
            return result;
        }, {});
        return res;
    };

    const exportExcel = async (columns: any) => {
        const col = reduceColumns(columns);
        const column_types = reduceColumnTypes(columns);
        const body: any = query_glp_alerts_visibility.mainTable.query;
        // if (body.data[0]) {
        //     delete body.data[0].offset;
        //     delete body.data[0].limit;
        // }

        const payloadGroupData: GroupDataWithTypesProps = {
            queries: [
                {
                    service: 'queryV1',
                    kwargs: {
                        groups: query_glp_alerts_visibility.mainTable.groupsBy,
                        name: query_glp_alerts_visibility.mainTable.table,
                    },
                    body: query_glp_alerts_visibility.mainTable.query,
                },
            ],
            export: {
                columns: col,
                column_types: column_types,
                format: 'xlsx',
            },
        };
        const export_query = { ...queries };
        delete export_query.sort_by;
        delete export_query.page;
        delete export_query.size;
        const res = await groupData(payloadGroupData, export_query, 'athena');
        window.open(res['url']);
    };
    const updateData = (newData: any) => {
        setMainTableData([]);
        setMainTableData(newData);
    };
    const memoizedMainTable = useMemo(
        () =>
            mainTableData && (
                <div className={styles['mainTable']}>
                    <Table
                        isSearch={true}
                        searchFunction={searchData}
                        searchText={searchText}
                        sortFunction={sortData}
                        columnsButton={true}
                        resetButton={true}
                        columns={mainColumns}
                        data={mainTableData}
                        count={totalDatas}
                        fetchData={fetchData}
                        title=""
                        exportData={exportExcelMain}
                        haveMoreItems={moreItems}
                        updateData={updateData}
                        isLoadingData={loadingMainData}
                        isLoadingDataScroll={isLoadingDataScroll}
                        allColumnsStyle=" max-w-[200px] "
                        exportLoader={downLoaderMain}
                        activeCompactMode={true}
                        onReset={resetFilters}
                        style={{ margin: '20px 53px', width: '94' }}
                    />
                </div>
            ),
        [
            mainTableData,
            searchData,
            searchText,
            sortData,
            mainColumns,
            mainTableData,
            totalDatas,
            fetchData,
            exportExcelMain,
            moreItems,
            updateData,
            loadingMainData,
            isLoadingDataScroll,
            downLoaderMain,
            resetFilters,
        ]
    );

    /*     const getDefinitions = () => {
        return (
            <div className="table-responsive">
                <h2 className={styles.tableTitle}>DEFINITIONS</h2>
                <hr className="small-line" />
                <table className="dataframe table thead-dark table-hover">
                    <thead>
                        <tr style={{ textAlign: 'right' }}>
                            <th>Acronym</th>
                            <th>Definition</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <strong>ETD</strong>
                            </td>
                            <td>PORT Estimated Time of Departure</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>ATD</strong>
                            </td>
                            <td>PORT Actual Time of Departure</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>ETA</strong>
                            </td>
                            <td>PORT Estimated Time of Arrival</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>ATA</strong>
                            </td>
                            <td>PORT Actual Time of Arrival</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Delayed Days</strong>
                            </td>
                            <td>
                                Number of days occurred after first ETA received for non-arrived
                                containers
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>ACTUAL ETA</strong>
                            </td>
                            <td>
                                Most Recent PORT Estimated Time of Arrival Date received for that
                                container
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }; */

    return (
        <div className={classNames(styles.root, className)}>
            <div className={'mainContainer'}>
                <div className="titlePage" style={{ border: 'none' }}>
                    <div className={styles.title}>
                        Alerts History
                        <span className={styles.lastRefresh}>Last Update: {lastRefresh}</span>
                    </div>
                </div>
                <div className="contentPage">
                    {/* <div className={styles.definitions}>{getDefinitions()}</div> */}
                    <div className={styles.mainTable}>{memoizedMainTable}</div>
                </div>
            </div>
        </div>
    );
};
