import { ColumnDef } from '@tanstack/react-table';
import styles from './columns.module.scss';
import { XCircleIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { getRecipents } from '../../services/python-services';
import { FilterFn } from '@tanstack/react-table';

type GLPAlerts = {
    code: string;
    name: string;
    created_date: string;
    country: string;
    alert_by_customer_setting: Object[];
};

const regex = /^\d{4}-\d{2}-\d{2}/;
const customArrIncludesSome: FilterFn<GLPAlerts> = (row, columnId, filterValue) => {
    const country = row.getValue(columnId) || 'NO COUNTRY';
    return filterValue.includes(country);
};

export const columnsAlerts: ColumnDef<GLPAlerts>[] = [
    {
        accessorKey: 'code',
        header: 'Ship to code',
        cell: (info: any) => info.getValue(),
        size: 50,
    },
    {
        accessorKey: 'name',
        cell: (info: any) => {
            return <div className={styles.shipName}>{info.getValue()}</div>;
        },
        header: 'Ship to name',
        size: 200,
    },
    /*     {
        accessorKey: 'sold_to_code',
        header: 'Sold to code',
        cell: (info: any) => {
            return <div className={styles.shipName}>{info.getValue()}</div>;
        },
        size: 50,
    },
    {
        accessorKey: 'sold_to_name',
        cell: (info: any) => {
            return <div className={styles.shipName}>{info.getValue()}</div>;
        },
        header: 'Sold to name',
        size: 200,
    }, */
    {
        accessorKey: 'created_date',
        id: 'created_date',
        header: 'Creation date',
        cell: (info: any) => {
            const date = info.getValue().match(regex)[0];
            return <div>{date}</div>;
        },
        size: 50,
    },
    {
        accessorKey: 'country.name',
        id: 'country',
        header: 'Creation country',
        filterFn: customArrIncludesSome,
        cell: (info: any) => {
            const country = info.getValue();
            let lastCountry;
            country === undefined ? (lastCountry = 'NO COUNTRY') : (lastCountry = country);
            return <div className={styles.shipName}>{lastCountry}</div>;
        },
        meta: {
            filterVariant: 'select',
        },
        size: 100,
    },
    {
        accessorKey: 'alert_by_customer_setting',
        header: 'Channel Recipients',
        id: 'Channel',
        cell: (info: any) => {
            const emails: any[] = [];
            info.getValue().map((recipient: any) => {
                return emails.push(recipient.recipent.email);
            });
            return (
                <div>
                    {emails.map((email: any, index: any) => (
                        <div className={styles.emailsAdded} key={index}>
                            {email}
                        </div>
                    ))}
                </div>
            );
        },
        size: 500,
    },
    {
        accessorKey: 'alert_by_customer_setting',
        header: 'Samsung Recipients',
        id: 'Samsung',
        cell: (info: any) => {
            const emails: any[] = [];
            info.getValue().map((recipient: any) => {
                return emails.push(recipient.recipent.email);
            });
            return (
                <div>
                    {emails.map((email: any, index: any) => (
                        <div className={styles.emailsAdded} key={index}>
                            {email}
                        </div>
                    ))}
                </div>
            );
        },
        size: 500,
    },
    /*     {
        id: 'addRecipients',
        header: 'Add recipients',
        cell: () => <div className={styles.addRecipents}>{}</div>,
        enableResizing: false,
    }, */
];
