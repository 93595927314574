import styles from './glp-monitoring-report.module.scss';
import classNames from 'classnames';
import { formatterDate, getQueryParams, viewInArrayViews } from '../../utils/functions';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState, useEffect, useContext, useMemo } from 'react';
import { HorizontalBarEChart } from '../horizontal-bar-e-chart/horizontal-bar-e-chart';
import { Column, GLPSales, GroupDataWithTypesProps } from '../../utils/interfaces';
import { groupData, postActionLogs, queryV1 } from '../../services/python-services';
import { queries_glp_monitoring, size_page } from '../../utils/constants';
import { Map } from '../map/map';
import { Table } from '../table/table';
import { PieEChart } from '../pie-e-chart/pie-e-chart';
import { FilterButton } from '../filter-button/filter-button';
import { FilterCard } from '../filter-card/filter-card';
import { Spinner } from 'flowbite-react';
import { GenericModal } from '../generic-modal/generic-modal';
import { RMContext } from '../../context/RMContext';
import { PortMap } from '../port-map/port-map';
export interface GlpMonitoringReportProps {
    name: string;
}

export const GlpMonitoringReport = ({ name }: GlpMonitoringReportProps) => {
    const cntr = window.location.search.split('?cntr=');
    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 90));
    //states to handle open modal eta and etd
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [sortBy, setSortBy] = useState('arrived');
    const [leadTimeShippingCompanyData, setLeadTimeShippingCompanyData] = useState([]);
    const [loadLeadTimeShippingCompanyData, setLoadLeadTimeShippingCompanyData] = useState(false);
    const [leadTimeDischargingPortData, setLeadTimeDischargingPortData] = useState([]);
    const [loadLeadTimeDischargingPortData, setLoadLeadTimeDischargingPortData] = useState(false);
    const [avgContinentData, setAvgContinentData] = useState([]);
    const [loadAvgContinentData, setLoadAvgContinentData] = useState(false);
    const [avgEtaProductGroup, setAvgEtaProductGroup] = useState([]);
    const [loadAvgEtaProductGroup, setLoadAvgEtaProductGroup] = useState(false);
    const [leadTimePortData, setLeadTimePortData] = useState([]);
    const initialTableState: any[] = [];
    const [mainTableData, setMainTableData] = useState(initialTableState);
    const [totalDatas, setTotalDatas] = useState(0);

    const [etaTableData, setEtaTableData] = useState(initialTableState);
    const [etdTableData, setEtdTableData] = useState(initialTableState);
    const [loadingMainData, setLoadingMainData] = useState(false);
    // const [downLoader, setDownLoader] = useState<boolean>(false);
    const [downLoaderMain, setDownLoaderMain] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const currentView = window.location.href.split('/').slice(-1)[0];
    const { sitemap } = useContext(RMContext).userData;

    useEffect(() => {
        const viewD = viewInArrayViews(currentView, sitemap);
        if (viewD?.hasOwnProperty('id')) {
            postActionLogs(viewD.id);
        }
    }, [sitemap]);

    //Pagination
    const [page, setPage] = useState(1);
    const [isLoadingDataScroll, setIsLoadingDataScroll] = useState(false);
    const [moreItems, setMoreItems] = useState(true);
    // FILTERS
    const [searchText, setSearchText] = useState('');
    const [applySearch, setApplySearch] = useState(true);
    const [customFilters, setCustomFilters] = useState<any>([]);
    const [executeApply, setExecuteApply] = useState(true);

    const filtersString = [
        'factory',
        'product-group-glp',
        'customer-glp',
        'country-glp',
        // 'delayeddays',
        // 'on-time',
        'delayed',
        'arrived',
    ];
    const [closedFilters, setClosedFilters] = useState(true);
    const {finalctry}: any = getQueryParams(window.location.search);
    const [queries, setQueries] = useState<GLPSales>({
        page: 1,
        size: size_page,
        sitefromname: null,
        divisionname: null,
        soldtoname: null,
        finalctry: finalctry!==undefined ? finalctry.toUpperCase():null,
        'delayeddays:not': null,
        delayeddays: null,
        searchGlp: null,
        sort_by: null,
        delayed: null,
        arrived: null,
        cntr_truckno: cntr.length > 1 ? '' + cntr[1] : null,
    });
    const [uniques, setUniques] = useState<GLPSales>({
        sitefromname: null,
        divisionname: null,
        soldtoname: null,
        finalctry: finalctry!==undefined ? finalctry.toUpperCase():null,
    });

    const clearValues = () => {
        setLeadTimeShippingCompanyData([]);
        setLeadTimeDischargingPortData([]);
        setAvgContinentData([]);
        setAvgEtaProductGroup([]);
        setLeadTimePortData([]);
        setMainTableData([]);
        setTotalDatas(0);
    };
    const resetModals = () => {
        setCurrentIndex(-1);
    };

    const resetFilters = (nameComponent: string = 'filter') => {
        //clearValues();
        if (nameComponent === 'table') {
            setClosedFilters(true);
        }
        const updatedQueries = {
            page: 1,
            size: size_page,
            sitefromname: null,
            divisionname: null,
            soldtoname: null,
            finalctry: finalctry!==undefined ? finalctry.toUpperCase():null,
            'delayeddays:not': null,
            delayeddays: null,
            searchGlp: null,
            sort_by: null,
            delayed: null,
            arrived: null,
        };
        setSearchText('');
        setApplySearch(true);
        setExecuteApply(true);
        setPage(1);
        setQueries(updatedQueries);
    };

    const applyFilters = (filters: any, type: string = '', apply: boolean = false) => {
        if (type === 'reset') {
            resetFilters();
        } else {
            //clearValues();
            const updatedQueries = {
                ...filters,
                page: 1,
                size: size_page,
                sort_by: sortBy !== '' ? sortBy : null,
            };
            setQueries(updatedQueries);
            setUniques(updatedQueries);
        }
        setExecuteApply(apply);
        setApplySearch(apply);
    };

    const closeFilter = (isClosed: boolean) => {
        setClosedFilters(isClosed);
    };

    const getLeadTimeShippingCompanyData = async () => {
        setLoadLeadTimeShippingCompanyData(true);
        const response = await queryV1(
            queries_glp_monitoring.leadTimeShippingCompany.table,
            queries_glp_monitoring.leadTimeShippingCompany.groupsBy,
            queries_glp_monitoring.leadTimeShippingCompany.query,
            queries,
            'athena'
        );
        if (response) {
            const sort_data = response?.data
                .filter((a: any) => a.avg_lead_time > 0 && a.shipping__carrier__grouping !== null)
                .sort(
                    (a: any, b: any) => parseFloat(a.avg_lead_time) - parseFloat(b.avg_lead_time)
                );
            setLeadTimeShippingCompanyData(sort_data);
        }
        setLoadLeadTimeShippingCompanyData(false);
    };

    const getLeadTimeDischargingPortData = async () => {
        setLoadLeadTimeDischargingPortData(true);
        const response = await queryV1(
            queries_glp_monitoring.leadTimeDischargingPort.table,
            queries_glp_monitoring.leadTimeDischargingPort.groupsBy,
            queries_glp_monitoring.leadTimeDischargingPort.query,
            queries,
            'athena'
        );
        if (response) {
            const sort_data = response?.data
                .filter((a: any) => a.avg_lead_time > 0 && a.discharging__port !== null)
                .sort(
                    (a: any, b: any) => parseFloat(a.avg_lead_time) - parseFloat(b.avg_lead_time)
                );
            setLeadTimeDischargingPortData(sort_data);
        }
        setLoadLeadTimeDischargingPortData(false);
    };

    const getAvgContinentData = async () => {
        setLoadAvgContinentData(true);
        let response = await queryV1(
            queries_glp_monitoring.avgEtaContinent.table,
            queries_glp_monitoring.avgEtaContinent.groupsBy,
            queries_glp_monitoring.avgEtaContinent.query
        );
        if (response) {
            setAvgContinentData(response.data.filter((q: any) => q.region_continent !== null));
        }
        setLoadAvgContinentData(false);
    };

    const getAvgProductGroupData = async () => {
        setLoadAvgEtaProductGroup(true);
        const response = await queryV1(
            queries_glp_monitoring.avgEtaProductGroup.table,
            queries_glp_monitoring.avgEtaProductGroup.groupsBy,
            queries_glp_monitoring.avgEtaProductGroup.query,
            queries,
            'athena'
        );
        if (response) {
            setAvgEtaProductGroup(response.data);
        }
        setLoadAvgEtaProductGroup(false);
    };

    const getLeadTimePortData = async () => {
        const response = await queryV1(
            queries_glp_monitoring.etdTimePort.table,
            queries_glp_monitoring.etdTimePort.groupsBy,
            queries_glp_monitoring.etdTimePort.query,
            queries,
            'athena'
        );
        if (response) {
            setLeadTimePortData(
                response?.data.filter(
                    (data: any) =>
                        parseFloat(data.count_deta) > 0 && data.discharging__port !== null
                )
            );
        }
    };
    const getMainTableData = async () => {
        setLoadingMainData(true);
        let newQueiries = {
            ...queries,
            page: 1,
            size: size_page,
            sort_by: sortBy !== '' ? sortBy : 'arrived',
        };
        const response = await queryV1(
            queries_glp_monitoring.mainTable.table,
            queries_glp_monitoring.mainTable.groupsBy,
            queries_glp_monitoring.mainTable.query,
            newQueiries,
            'athena'
        );
        if (response && response.data) {
            //add position in each row
            const newData = response.data?.map((row: any, index: number) => {
                return { ...row, index: index };
            });
            setMainTableData(newData);
        }
        setLoadingMainData(false);
    };
    const getTotalDatas = async () => {
        let filter_queries = { ...queries };
        filter_queries.sort_by = '';
        delete filter_queries.page;
        delete filter_queries.size;
        const response = await queryV1(
            queries_glp_monitoring.totalMainTable.table,
            queries_glp_monitoring.totalMainTable.groupsBy,
            queries_glp_monitoring.totalMainTable.query,
            filter_queries,
            'athena'
        );
        if (response) {
            setTotalDatas(response?.data[0].totals);
            if (parseInt(response?.data[0].totals) <= mainTableData.length) {
                setMoreItems(false);
            } else {
                setMoreItems(true);
            }
        }
    };

    useEffect(() => {
        if (executeApply) {
            //console.log("one time")
            getLeadTimeShippingCompanyData();
            getLeadTimeDischargingPortData();
            getAvgContinentData();
            getAvgProductGroupData();
            getLeadTimePortData();
            setExecuteApply(false);
        }
        if (applySearch) {
            //console.log("only one time")
            getMainTableData();
            getTotalDatas();
            setApplySearch(false);
        }
        if (dataLoaded === false) {
            //console.log("norepeat")
            setDataLoaded(true);
        }
    }, [queries]);

    useEffect(() => {
        if (currentIndex !== -1) {
            getEtaDataByRow();
            getEtdDataByRow();
        }
    }, [currentIndex]);

    const convertDataToChart = (datas: any[]) => {
        return datas.map((data: object) => {
            return {
                label: Object.values(data)[0],
                value: parseFloat(parseFloat(Object.values(data)[1]).toFixed(2)),
            };
        });
    };

    const data_chart_changes_per_continent = useMemo(() => {
        return convertDataToChart(avgContinentData);
    }, [avgContinentData]);

    const data_chart_changes_per_product_group = useMemo(() => {
        return convertDataToChart(avgEtaProductGroup);
    }, [avgEtaProductGroup]);

    //try memoizing the component (the jsx, instead of just the data. )

    const data_chart_changes_per_shipping_company = useMemo(() => {
        return (
            <div className={styles['chartShippingM']}>
                {!loadLeadTimeShippingCompanyData ? (
                    <HorizontalBarEChart
                        className={'chartShippingM'}
                        title="Date Changes Per Shipping Company(AVG)"
                        data_chart={convertDataToChart(leadTimeShippingCompanyData)}
                    />
                ) : (
                    <Spinner size="sm" />
                )}
            </div>
        );
    }, [loadLeadTimeShippingCompanyData, leadTimeShippingCompanyData]);

    const data_chart_changes_per_discharging_port = useMemo(() => {
        return (
            <div className={styles['chartF']}>
                {!loadLeadTimeDischargingPortData ? (
                    <HorizontalBarEChart
                        className={'chartF'}
                        title="Date Changes Per Discharging Port (AVG)"
                        data_chart={convertDataToChart(leadTimeDischargingPortData)}
                    />
                ) : (
                    <Spinner size="sm" />
                )}
            </div>
        );
    }, [loadLeadTimeDischargingPortData, leadTimeDischargingPortData]);

    const convertDataToMap = (datas: any[]) => {
        return datas.map((data: any) => {
            return {
                port: data['discharging__port'],
                value: parseFloat(data['count_deta']).toFixed(2),
                lat: parseFloat(data['latitude']),
                lng: parseFloat(data['longitude']),
            };
        });
    };

    const openModalEtaChanges = (index: number) => {
        setCurrentIndex(index);
        handleOpenModalEta();
    };
    const openModalEtdChanges = (index: number) => {
        setCurrentIndex(index);
        handleOpenModalEtd();
    };
    const getEtaDataByRow = () => {
        if (currentIndex !== -1) {
            const row: any = mainTableData[currentIndex];
            const eta_data = [
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 1,
                    date: row.port_etadate_0,
                    issued: row.file_datechangeeta_0,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 2,
                    date: row.port_etadate_1,
                    issued: row.file_datechangeeta_1,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 3,
                    date: row.port_etadate_2,
                    issued: row.file_datechangeeta_2,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 4,
                    date: row.port_etadate_3,
                    issued: row.file_datechangeeta_3,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 5,
                    date: row.port_etadate_4,
                    issued: row.file_datechangeeta_4,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 6,
                    date: row.port_etadate_5,
                    issued: row.file_datechangeeta_5,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 7,
                    date: row.port_etadate_6,
                    issued: row.file_datechangeeta_6,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 8,
                    date: row.port_etadate_7,
                    issued: row.file_datechangeeta_7,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 9,
                    date: row.port_etadate_8,
                    issued: row.file_datechangeeta_8,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 'Last',
                    date: row.port_etadate,
                    issued: row.file_date,
                },
            ];
            setEtaTableData(eta_data);
            return eta_data;
        } else {
            return [];
        }
    };
    const getEtdDataByRow = () => {
        if (currentIndex !== -1) {
            const row = mainTableData[currentIndex];
            const etd_data = [
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 1,
                    date: row.port_etd_date_0,
                    issued: row.file_datechangeetd_0,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 2,
                    date: row.port_etd_date_1,
                    issued: row.file_datechangeetd_1,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 3,
                    date: row.port_etd_date_2,
                    issued: row.file_datechangeetd_2,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 4,
                    date: row.port_etd_date_3,
                    issued: row.file_datechangeetd_3,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 5,
                    date: row.port_etd_date_4,
                    issued: row.file_datechangeetd_4,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 6,
                    date: row.port_etd_date_5,
                    issued: row.file_datechangeetd_5,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 7,
                    date: row.port_etd_date_6,
                    issued: row.file_datechangeetd_6,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 8,
                    date: row.port_etd_date_7,
                    issued: row.file_datechangeetd_7,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 9,
                    date: row.port_etd_date_8,
                    issued: row.file_datechangeetd_8,
                },
                {
                    arrived_f: row.arrived_f,
                    po_no: row.po_no,
                    sku: row.sku,
                    cntr_truckno: row.cntr_truckno,
                    number_change: 'Last',
                    date: row.actual_port_etd_date,
                    issued: row.file_date,
                },
            ];
            setEtdTableData(etd_data);
            return etd_data;
        }
        return [];
    };
    const mainColumns: Column[] = [
        {
            id: 'arrived',
            name: 'Arrived',
            prop: 'arrived',
            open: false,
            show: true,
            style: { minWidth: '85px' },
            formatting: (v: any) => {
                return (
                    <div>
                        {' '}
                        {v === 'true' ? 
                            "Yes"
                         : 
                            "No"
                        }{' '}
                    </div>
                );
            },
        },
        {
            id: 'po_no',
            name: 'PO #',
            prop: 'po_no',
            open: false,
            show: false,
            formatting: (v: any) => <div>{v.toString()}</div>,
            style: { minWidth: '100px' },
        },
        {
            id: 'sku',
            name: 'SKU',
            prop: 'sku',
            open: false,
            show: true,
            formatting: (v: any) => <div>{v.toString()}</div>,
            style: { minWidth: '150px' },
        },
        {
            id: 'master_bl',
            name: 'BL #',
            prop: 'master_bl',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: 'cntr_truckno',
            name: 'CNTR #',
            prop: 'cntr_truckno',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'so',
            name: 'SO #',
            prop: 'so',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'shiptoname',
            name: 'Ship To',
            prop: 'shiptoname',
            open: false,
            show: true,
            style: { minWidth: '260px' },
        },
        {
            id: 'soldtoname',
            name: 'Sold To Name',
            prop: 'soldtoname',
            open: false,
            show: true,
            style: { minWidth: '260px' },
        },
        {
            id: 'departurectry',
            name: 'DEP',
            prop: 'departurectry',
            open: false,
            show: true,
            style: { minWidth: '90px' },
        },
        {
            id: 'departureport',
            name: 'Dep Port',
            prop: 'departureport',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: 'dischargingctry',
            name: 'DIS',
            prop: 'dischargingctry',
            open: false,
            show: true,
            style: { minWidth: '90px' ,maxWidth: '90px' },
        },
        {
            id: 'discharging__port',
            name: 'Dis Port',
            prop: 'discharging__port',
            open: false,
            show: true,
            style: { minWidth: '260px' },
        },
        {
            id: 'finalctry',
            name: 'DES',
            prop: 'finalctry',
            open: false,
            show: true,
            style: { minWidth: '85px' },
        },
        {
            id: 'finalport',
            name: 'Des Port',
            prop: 'finalport',
            open: false,
            show: true,
            style: { minWidth: '170px' },
        },
        {
            id: 'incoterms1',
            name: 'Inco Terms',
            prop: 'incoterms1',
            open: false,
            show: true,
            style: { minWidth: '90px' },
        },
        {
            id: 'shipping__carrier__grouping',
            name: 'Shipping',
            prop: 'shipping__carrier__grouping',
            open: false,
            show: true,
            style: { minWidth: '210px' },
        },
        {
            id: 'port_atddate',
            name: 'ATD Date',
            prop: 'port_atddate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'atd_rpa_date',
            name: 'ATD RPA Date',
            prop: 'atd_rpa_date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'forecastlt',
            name: 'Forecast LT',
            prop: 'forecastlt',
            open: false,
            show: false,
            cellType: 'pl.Int64',
            style: { minWidth: '60px' },
        },
        // {
        //     id: 'actuallt',
        //     name: 'Actual LT',
        //     prop: 'actuallt',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Int64',
        //     style: { minWidth: '60px' },
        // },
        {
            id: 'historicleadtime',
            name: 'Lead Time',
            prop: 'historicleadtime',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '90px', marginLeft: '1px', borderLeft: '1px solid #000' },
        },
        {
            id: 'contract_lty23',
            name: 'LT Y23',
            prop: 'contract_lty23',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '85px' },
            formatting: (v: any) => {
                return `${v !== null ? parseFloat(v).toFixed(0) : ''}`;
            },
        },
        {
            id: 'gap',
            name: 'Gap',
            prop: 'gap',
            open: false,
            show: true,
            formatting: (v: any) => {
                return (
                    <div
                        style={{
                            color: v <= 0 ? '#137fc4' : '#a4282c',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginRight: '2px',
                        }}
                    >
                        <strong>
                            {v !== null && typeof v === 'number'
                                ? v.toFixed(0)
                                : typeof v === 'string'
                                ? parseFloat(v).toFixed(0)
                                : v}
                        </strong>
                    </div>
                );
            },
            cellType: 'pl.Int64',
            style: { minWidth: '90px', marginRight: '1px', borderRight: '1px solid #000' },
        },
        {
            id: 'count_eta',
            name: 'ETA DATE CHANGE',
            prop: 'count_eta',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            formatting: (v: any) => <span>{parseInt(v) > 0 ? v : ''}</span>,
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate_0',
            name: '1st ETA Change',
            prop: 'port_etadate_0',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'file_datechangeeta_0',
            name: 'Update Date 1',
            prop: 'file_datechangeeta_0',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etadate',
            name: 'Last Eta Date',
            prop: 'port_etadate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '150px' },
        },
        {
            id: 'file_date',
            name: 'Last Etd Update date',
            prop: 'file_date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '150px' },
        },
        {
            id: 'index',
            name: 'Details ETA',
            prop: 'index',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
            formatting: (v: any) => (
                <button className="btn btn-success" onClick={() => openModalEtaChanges(v)}>
                    {' '}
                    <strong> View </strong>
                </button>
            ),
        },
        // {
        //     id: 'port_etadate_2',
        //     name: '2nd ETA Change',
        //     prop: 'port_etadate_2',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'file_datechangeeta_2',
        //     name: 'Update Date 2',
        //     prop: 'file_datechangeeta_2',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etadate_3',
        //     name: '3rd ETA Change',
        //     prop: 'port_etadate_3',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'file_datechangeeta_3',
        //     name: 'Update Date 3',
        //     prop: 'file_datechangeeta_3',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etadate_4',
        //     name: '4th ETA Change',
        //     prop: 'port_etadate_4',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'file_datechangeeta_4',
        //     name: 'Update Date 4',
        //     prop: 'file_datechangeeta_4',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etadate_5',
        //     name: '5th ETA Change',
        //     prop: 'port_etadate_5',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'file_datechangeeta_5',
        //     name: 'Update Date 5',
        //     prop: 'file_datechangeeta_5',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etadate_6',
        //     name: '6th ETA Change',
        //     prop: 'port_etadate_6',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'file_datechangeeta_6',
        //     name: 'Update Date 6',
        //     prop: 'file_datechangeeta_6',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etadate_7',
        //     name: '7th ETA Change',
        //     prop: 'port_etadate_7',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'file_datechangeeta_7',
        //     name: 'Update Date 7',
        //     prop: 'file_datechangeeta_7',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etadate_8',
        //     name: '8th ETA Change',
        //     prop: 'port_etadate_8',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'file_datechangeeta_8',
        //     name: 'Update Date 8',
        //     prop: 'file_datechangeeta_8',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etadate_9',
        //     name: '9th ETA Change',
        //     prop: 'port_etadate_9',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'file_datechangeeta_9',
        //     name: 'Update Date 9',
        //     prop: 'file_datechangeeta_9',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        {
            id: 'port_atddate',
            name: 'Dep Date',
            prop: 'port_atddate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_atadate',
            name: 'Ata Date',
            prop: 'port_atadate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },

        {
            id: 'historicleadtime',
            name: 'Lead Time',
            prop: 'historicleadtime',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '100px' },
        },
        {
            id: 'count_etd',
            name: 'ETD DATE CHANGE',
            prop: 'count_etd',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            formatting: (v: any) => <span>{parseInt(v) > 0 ? v : ''}</span>,
            style: { minWidth: '120px' },
        },
        {
            id: 'port_etd_date_0',
            name: '1st ETD Change Change',
            prop: 'port_etd_date_0',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '160px' },
        },
        {
            id: 'file_datechangeetd_0',
            name: 'Update date # 1',
            prop: 'file_datechangeetd_0',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'actual_port_etd_date',
            name: 'Last Etd Date',
            prop: 'actual_port_etd_date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'file_date',
            name: 'Last Etd Update date ',
            prop: 'file_date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '150px' },
        },
        {
            id: 'index',
            name: 'Details ETD',
            prop: 'index',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
            formatting: (v: any) => (
                <button className="btn btn-success" onClick={() => openModalEtdChanges(v)}>
                    {' '}
                    <strong> View</strong>
                </button>
            ),
        },
        // {
        //     id: 'port_etd_date_2',
        //     name: '2nd ETD Change',
        //     prop: 'port_etd_date_2',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },
        // },
        // {
        //     id: 'file_datechangeetd_2',
        //     name: 'Update date # 2',
        //     prop: 'file_datechangeetd_2',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etd_date_3',
        //     name: '3rd ETD Change',
        //     prop: 'port_etd_date_3',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },
        // },
        // {
        //     id: 'file_datechangeetd_3',
        //     name: 'Update date # 3',
        //     prop: 'file_datechangeetd_3',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etd_date_4',
        //     name: '4th ETD Change',
        //     prop: 'port_etd_date_4',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },
        // },
        // {
        //     id: 'file_datechangeetd_4',
        //     name: 'Update date # 4',
        //     prop: 'file_datechangeetd_4',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etd_date_5',
        //     name: '5th ETD Change',
        //     prop: 'port_etd_date_5',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },
        // },
        // {
        //     id: 'file_datechangeetd_5',
        //     name: 'Update date # 5',
        //     prop: 'file_datechangeetd_5',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etd_date_6',
        //     name: '6th ETD Change',
        //     prop: 'port_etd_date_6',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },
        // },
        // {
        //     id: 'file_datechangeetd_6',
        //     name: 'Update date # 6',
        //     prop: 'file_datechangeetd_6',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etd_date_7',
        //     name: '7th ETD Change',
        //     prop: 'port_etd_date_7',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },
        // },
        // {
        //     id: 'file_datechangeetd_7',
        //     name: 'Update date # 7',
        //     prop: 'file_datechangeetd_7',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etd_date_8',
        //     name: '8th ETD Change',
        //     prop: 'port_etd_date_8',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },
        // },
        // {
        //     id: 'file_datechangeetd_8',
        //     name: 'Update date # 8',
        //     prop: 'file_datechangeetd_8',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
        // {
        //     id: 'port_etd_date_9',
        //     name: '9th ETD Change',
        //     prop: 'port_etd_date_9',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },
        // },
        // {
        //     id: 'file_datechangeetd_9',
        //     name: 'Update date # 9',
        //     prop: 'file_datechangeetd_9',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Date',
        //     style: { minWidth: '120px' },

        // },
    ];

    const etaColumns: Column[] = [
        {
            id: 'arrived',
            name: 'Arrived',
            prop: 'arrived',
            open: false,
            show: true,
            style: { minWidth: '100px' },
            formatting: (v: any) => {
                return (
                    <div>
                        {' '}
                        {v === 'true' ? (
                           'Yes'
                        ) : (
                            'No'
                        )}{' '}
                    </div>
                );
            },
        },
        {
            id: 'po_no',
            name: 'PO #',
            prop: 'po_no',
            open: false,
            show: true,
            formatting: (v: any) => <div>{v.toString()}</div>,
            style: { minWidth: '100px' },
        },
        {
            id: 'sku',
            name: 'SKU',
            prop: 'sku',
            open: false,
            show: true,
            formatting: (v: any) => <div>{v.toString()}</div>,
            style: { minWidth: '150px' },
        },
        {
            id: 'cntr_truckno',
            name: 'CNTR #',
            prop: 'cntr_truckno',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'number_change',
            name: '# of change',
            prop: 'number_change',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            formatting: (v: any) => <span>{v}</span>,
            style: { minWidth: '100px' },
        },
        {
            id: 'date',
            name: 'ETA',
            prop: 'date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            formatting: (v: any) => <span>{v}</span>,
            style: { minWidth: '160px' },
        },
        {
            id: 'issued',
            name: 'Issued',
            prop: 'issued',
            open: false,
            show: true,
            cellType: 'pl.Date',
            formatting: (v: any) => <span>{v}</span>,
            style: { minWidth: '160px' },
        },
    ];
    const etdColumns: Column[] = [
        {
            id: 'arrived_f',
            name: 'Arrived',
            prop: 'arrived_f',
            open: false,
            show: true,
            style: { minWidth: '100px' },
            formatting: (v: any) => {
                return (
                    <div>
                        {' '}
                        {v === 'true' ? (
                          'Yes'
                        ) : (
                            'No'
                        )}{' '}
                    </div>
                );
            },
        },
        {
            id: 'po_no',
            name: 'PO #',
            prop: 'po_no',
            open: false,
            show: true,
            formatting: (v: any) => <div>{v.toString()}</div>,
            style: { minWidth: '100px' },
        },
        {
            id: 'sku',
            name: 'SKU',
            prop: 'sku',
            open: false,
            show: true,
            formatting: (v: any) => <div>{v.toString()}</div>,
            style: { minWidth: '150px' },
        },
        {
            id: 'cntr_truckno',
            name: 'CNTR #',
            prop: 'cntr_truckno',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'number_change',
            name: '# of change',
            prop: 'number_change',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            formatting: (v: any) => <span>{v}</span>,
            style: { minWidth: '100px' },
        },
        {
            id: 'date',
            name: 'ETD',
            prop: 'date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            formatting: (v: any) => <span>{v}</span>,
            style: { minWidth: '160px' },
        },
        {
            id: 'issued',
            name: 'Issued',
            prop: 'issued',
            open: false,
            show: true,
            cellType: 'pl.Date',
            formatting: (v: any) => <span>{v}</span>,
            style: { minWidth: '160px' },
        },
    ];

    const handleCloseModalEta = () => {
        const dialog = document.getElementById('eta');
        //@ts-expect-error
        if (dialog) dialog.close();
        resetModals();
    };
    const handleCloseModalEtd = () => {
        const dialog = document.getElementById('etd');
        //@ts-expect-error
        if (dialog) dialog.close();
        resetModals();
    };

    const handleOpenModalEta = () => {
        const dialog = document.getElementById('eta');
        //@ts-expect-error
        if (dialog) dialog.showModal();
        getEtaDataByRow();
    };
    const handleOpenModalEtd = () => {
        const dialog = document.getElementById('etd');
        //@ts-expect-error
        if (dialog) dialog.showModal();
        getEtdDataByRow();
    };
    const reduceColumns = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (column.show === true && !column.name.includes('Details')) {
                result[column.id] = column.name;
            }
            return result;
        }, {});
        return res;
    };

    const reduceColumnTypes = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (
                column.show === true &&
                column.cellType !== undefined &&
                column.cellType !== null &&
                !column.name.includes('Details')
            ) {
                result[column.id] = column.cellType;
            }
            return result;
        }, {});
        return res;
    };

    const exportExcel = async (columns: any) => {
        const col = reduceColumns(columns);
        const column_types = reduceColumnTypes(columns);
        const body: any = queries_glp_monitoring.mainTable.query;
        delete body.data[0].offset;
        delete body.data[0].limit;

        const payloadGroupData: GroupDataWithTypesProps = {
            queries: [
                {
                    service: 'queryV1',
                    kwargs: {
                        groups: queries_glp_monitoring.mainTable.groupsBy,
                        name: queries_glp_monitoring.mainTable.table,
                    },
                    body: queries_glp_monitoring.mainTable.query,
                },
            ],
            export: {
                columns: col,
                column_types: column_types,
                format: 'xlsx',
            },
        };
        const export_query = { ...queries };
        delete export_query.sort_by;
        delete export_query.page;
        delete export_query.size;
        const res = await groupData(payloadGroupData, export_query, 'athena');
        window.open(res['url']);
    };
    const updateData = (newData: any) => {
        setMainTableData([]);
        setMainTableData(newData);
    };

    const updateDataEta = (newData: any) => {
        setEtaTableData([]);
        setEtaTableData(newData);
    };
    const updateDataEtd = (newData: any) => {
        setEtdTableData([]);
        setEtdTableData(newData);
    };

    const searchData = (type: any) => {
        setApplySearch(true);
        type = type.replace(/\s+/g, ' ').trim();
        setSearchText(type);
        if (type === '') {
            //clearValues();
            setQueries({ ...queries, searchGlp: null });
        } else if (type.length > 2) {
            //clearValues();
            setQueries({ ...queries, searchGlp: type.toUpperCase() });
        }
    };
    const sortData = (type: any) => {
        setApplySearch(true);
        setPage(1);
        if (type.name === 'Descending') {
            //clearValues();
            setSortBy(`${type.prop},desc`);
            setQueries({
                ...queries,
                page: 1,
                size: size_page * 1,
                sort_by: `${type.prop},desc`,
            });
        } else {
            //clearValues();
            setSortBy(`${type.prop}`);
            setQueries({ ...queries, page: 1, size: size_page * 1, sort_by: `${type.prop}` });
        }
        //setPage(1);
    };

    const fetchData = async (isScroll: boolean = false) => {
        // avoid multiple call to the fetchData
        if (loadingMainData) {
            return;
        }
        // console.log('Fetching data', queries)
        const updatedQueries = {
            ...queries,
            page: page + 1,
            size: size_page,
            sort_by: sortBy !== '' ? sortBy : null,
        };
        setQueries(updatedQueries);
        setIsLoadingDataScroll(isScroll);
        setLoadingMainData(true); // Establecer isLoadingData a true antes de la solicitud

        try {
            // make and run request
            const response = await queryV1(
                queries_glp_monitoring.mainTable.table,
                queries_glp_monitoring.mainTable.groupsBy,
                queries_glp_monitoring.mainTable.query,
                updatedQueries,
                'athena'
            );
            // update data and state variables
            const newDataToAdd = response.data?.map((row: any, index: number) => {
                return { ...row, index: index + page * size_page };
            });
            const newData: any[] = [...mainTableData, ...newDataToAdd];
            setMainTableData(isScroll ? newData : response.data);
            if (newData.length >= totalDatas) {
                setMoreItems(false);
            } else {
                setPage((prev) => prev + 1);
            }
            setExecuteApply(false);
            setApplySearch(false);

            // Reset loading states
            setLoadingMainData(false);
            setIsLoadingDataScroll(false);
        } catch (error) {
            // Reset loading states
            setIsLoadingDataScroll(false);
            setLoadingMainData(false);
        }
    };

    // const exportExcelCombined = async (columns: any) => {
    //     setDownLoader(true);
    //     await exportExcel(columns);
    //     setDownLoader(false);
    // }

    const exportExcelMain = async (columns: any) => {
        console.log('COLUMNS', columns);
        setDownLoaderMain(true);
        await exportExcel(columns);
        setDownLoaderMain(false);
    };
    const getModalEtaTable = () => {
        return (
            <div className={styles.tableModal}>
                <Table
                    isSearch={false}
                    searchFunction={searchData}
                    searchText={searchText}
                    // sortFunction={sortData}
                    columnsButton={false}
                    resetButton={false}
                    columns={etaColumns}
                    data={etaTableData}
                    count={totalDatas}
                    fetchData={fetchData}
                    title={'Details ETA changes'}
                    // exportData={exportExcelMain}
                    haveMoreItems={false}
                    updateData={updateDataEta}
                    isLoadingData={loadingMainData}
                    isLoadingDataScroll={isLoadingDataScroll}
                    allColumnsStyle=" max-w-[200px] "
                    exportLoader={downLoaderMain}
                    activeCompactMode={true}
                    onReset={resetFilters}
                    isExpand={false}
                    hasTotal={false}
                    toolsNative={true}
                />
            </div>
        );
    };
    const getModalEtdTable = () => {
        return (
            <div className={styles.tableModal}>
                <Table
                    isSearch={false}
                    searchFunction={searchData}
                    searchText={searchText}
                    // sortFunction={sortData}
                    columnsButton={false}
                    resetButton={false}
                    columns={etdColumns}
                    data={etdTableData}
                    count={totalDatas}
                    fetchData={fetchData}
                    title={'Details ETD changes'}
                    // exportData={exportExcelMain}
                    haveMoreItems={false}
                    updateData={updateDataEtd}
                    isLoadingData={loadingMainData}
                    isLoadingDataScroll={isLoadingDataScroll}
                    allColumnsStyle=" max-w-[200px] "
                    exportLoader={downLoaderMain}
                    activeCompactMode={true}
                    onReset={resetFilters}
                    isExpand={false}
                    hasTotal={false}
                    toolsNative={true}
                />
            </div>
        );
    };

    const memoizedMainTable = useMemo(
        () =>
           (
                <div className={styles['mainTable']}>
                    <Table
                        isSearch={true}
                        searchFunction={searchData}
                        searchText={searchText}
                        sortFunction={sortData}
                        columnsButton={true}
                        resetButton={true}
                        columns={mainColumns}
                        data={mainTableData}
                        count={totalDatas}
                        fetchData={fetchData}
                        title=""
                        exportData={exportExcelMain}
                        haveMoreItems={moreItems}
                        updateData={updateData}
                        isLoadingData={loadingMainData}
                        isLoadingDataScroll={isLoadingDataScroll}
                        allColumnsStyle=" max-w-[200px] "
                        exportLoader={downLoaderMain}
                        activeCompactMode={true}
                        onReset={resetFilters}
                        style={{ margin: '20px 53px', width: '94' }}
                    />
                </div>
            ),
        [
            mainTableData,
            searchData,
            searchText,
            sortData,
            mainColumns,
            mainTableData,
            totalDatas,
            fetchData,
            exportExcelMain,
            moreItems,
            updateData,
            loadingMainData,
            isLoadingDataScroll,
            downLoaderMain,
            resetFilters,
        ]
    );

    const getComponents = () => {
        return (
            <div className={styles.root}>
                <div className={styles.upper}>
                    <div className={styles.headerReport}>
                        <span className={styles.descriptionCountry}>{finalctry!==undefined?finalctry?.toUpperCase():'SELA'}</span>
                        <span className={styles.tittleReport}>
                            Logistic Leadtime Tracking System: Monitoring Version
                        </span>
                    </div>

                    <div className={styles.subHeader}>
                        <div>
                            {closedFilters && (
                                <FilterButton
                                    className="hidden"
                                    close={closeFilter}
                                    top={154}
                                    regularFormat={true}
                                />
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                            }}
                        >
                            <div className={styles.textReport}>
                                <span>
                                    Period: {formatterDate(priorDate)} to {formatterDate(today)}
                                </span>
                            </div>
                            <div className={styles.textUpdateReport}>
                                <span>
                                    Update by GLP Outbound Tracking View at: {formatterDate(today)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.filterSection}>
                    <div className={styles.section1}>
                        <div className={styles['chartTransport'] + ' ' + styles.textReportTableDT}>
                            {!loadAvgContinentData ? (
                                <PieEChart
                                    className={'chartTransport'}
                                    title="ETA Changes per Continent (AVG)"
                                    name="AVG ETA Changes"
                                    data_chart={data_chart_changes_per_continent}
                                />
                            ) : (
                                <Spinner size="sm" />
                            )}
                        </div>
                    </div>
                    <div className={styles.section2}>
                        <div className={styles['chartC'] + ' ' + styles.textReportTableDT}>
                            {!loadAvgEtaProductGroup ? (
                                <PieEChart
                                    className={'chartC'}
                                    title="ETA Changes per Product Group (AVG)"
                                    name="AVG ETA Changes"
                                    data_chart={data_chart_changes_per_product_group}
                                />
                            ) : (
                                <Spinner size="sm" />
                            )}
                        </div>
                    </div>
                    <div className={styles.section3}>{data_chart_changes_per_shipping_company}</div>
                    <div className={styles.section4}>{data_chart_changes_per_discharging_port}</div>
                    <div className={styles.section5}>
                        <div className={styles['chartMap']}>
                            <PortMap
                                className="glpMap"
                                title="Monitoring"
                                /* data_chart={{
                                    data: [],
                                    geoCoorMap: convertDataToMap(leadTimePortData),
                                }} */
                                lead_time={leadTimePortData}
                                center_map={{ lat: 15.0, lng: -73.0 }}
                                zoom={3.1}
                            />
                        </div>
                    </div>
                </div>
                <div>{memoizedMainTable}</div>
                <GenericModal
                    closeDialog={handleCloseModalEta}
                    haveComponent={true}
                    getComponent={getModalEtaTable()}
                    name="eta"
                ></GenericModal>
                <GenericModal
                    closeDialog={handleCloseModalEtd}
                    haveComponent={true}
                    getComponent={getModalEtdTable()}
                    name="etd"
                ></GenericModal>
            </div>
        );
    };

    return (
        <div className={classNames(styles.root) + ' flex h-[98vh] w-screen ' + name}>
            {!closedFilters && (
                <FilterCard
                    close={closeFilter}
                    applyFilters={applyFilters}
                    columns={mainColumns}
                    setUniques={setUniques}
                    filters={filtersString}
                    file={'glp-shipmentoptimized'}
                    uniques={uniques}
                    by="athena"
                    onReset={clearValues}
                    setExecuteApply={setExecuteApply}
                    setApplySearch={setApplySearch}
                    setCustomFilters={setCustomFilters}
                    customFilters={customFilters}
                    isColapsed={true}
                />
            )}
            <div className={closedFilters ? 'w-full' : 'w-[80%]'}>
                {dataLoaded && getComponents()}
            </div>
        </div>
    );
};
