import { Link, useParams } from 'react-router-dom';
import styles from './gallery-reports.module.scss';
import classNames from 'classnames';
import { AddPBIReport } from '../add-pbi-report/add-pbi-report';
import { GenericModal } from '../generic-modal/generic-modal';
import { useContext, useEffect, useState } from 'react';
import {
    getAllCountries,
    getAllDivisions,
    getAllGroups,
    getAllUsers,
    getAllViewTypesByType,
} from '../../services/python-services';
import Notiflix from 'notiflix';
import {
    addCountriesToContext,
    capitalizeWords,
    getQueryParams,
    getSubViewsByType,
    getTreeData,
    isValidString,
    registerViewLog,
    sortArrayByString,
    updateCurrentRoueteToContext,
    viewInArrayViews,
    findDescription,
} from '../../utils/functions';
import { RMContext } from '../../context/RMContext';
import { AddCover } from '../add-cover/add-cover';
// import { PbiEmbed } from '../pbi-embed/pbi-embed';
import { Tabs } from '../tabs/tabs';
import { Spinner } from 'flowbite-react';
import { AddSubView } from '../add-sub-view/add-sub-view';
export interface GalleryReportsProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const GalleryReports = ({ className }: GalleryReportsProps) => {
    const { parent, child, view } = useParams();
    const { setUserData, userData } = useContext(RMContext);
    const { sitemap, currentRoute, userGlobals } = useContext(RMContext).userData;
    const [reports, setReports] = useState<any[]>([]);
    /* const sitemap = userData.sitemap; */
    const [openModal, setOpenModal] = useState(false);
    const [openModalCover, setOpenModalCover] = useState(false);
    const [openModalSubView, setOpenModalSubView] = useState(false);
    const [isModalEdit, setIsModalEdit] = useState(false);
    const [countriesData, setCountriesData] = useState<any[]>(userData.countries);
    const [loadingCountries, setLoadingCountries] = useState(false);
    const [coverData, setCoverData] = useState<any[]>([]);
    const [subViewData, setSubViesData] = useState<any[]>([]);
    const [disionsData, setDivisionsData] = useState<any[]>([]);
    const [groupsData, setGroupsData] = useState<any[]>([]);
    const [usersData, setUsersData] = useState<any[]>([]);
    const [selectedReportsData, setSelectedReportsData] = useState<any>(null);
    const [newReport, setNewReport] = useState<any>('new');
    const [loaddedData, setLoaddedData] = useState(false);
    const [updatedData, setUpdatedData] = useState(false);
    const [newCover, setNewCover] = useState(false);
    const [newSubView, setNewSubView] = useState(false);
    const [isLastLevel, setIsLastLevel] = useState(false);
    const [openModalEmbedded, setOpenModalEmbedded] = useState(false);
    const [selectedCountryName, setSelectedCountryName] = useState('');
    const [currentViewData, setCurrentViewData] = useState<any>(null);
    const queries: any = getQueryParams(window.location.search);

    const handleCloseModal = () => {
        setOpenModal(false);
        const dialog = document.getElementById('add-report');
        //@ts-expect-error
        if (dialog) dialog?.close();
        setNewReport('new');
    };

    const handleCloseModalEmbedded = () => {
        setOpenModalEmbedded(false);
        const dialog = document.getElementById('pbiEmbedded');
        //@ts-expect-error
        if (dialog) dialog?.close();
        // updateCurrentRoueteToContext(`${currentViewData.parent}`)
    };

    const handleCloseAndUpdateModal = (msj: any) => {
        if (msj.toString().includes('successfully')) Notiflix.Notify.success(msj.toString());
        else Notiflix.Notify.failure(msj.toString());
        handleCloseModal();
        setNewReport('new');
        //Create de report
    };

    const handleCloseModalCover = () => {
        setOpenModalCover(false);
        const dialog = document.getElementById('cover-report');
        //@ts-expect-error
        if (dialog) dialog?.close();
    };
    const handleCloseAndUpdateModalCover = (msj: any, created: boolean) => {
        if (msj.toString().includes('successfully')) Notiflix.Notify.success(msj.toString());
        else Notiflix.Notify.failure(msj.toString());
        handleCloseModalCover();
        setNewCover(created);
        //Create de report
    };

    const handleCloseModalSubView = () => {
        setOpenModalSubView(false);
        const dialog = document.getElementById('sub-view-report');
        //@ts-expect-error
        if (dialog) dialog?.close();
    };
    const handleCloseAndUpdateModalSubView = (msj: any, created: boolean) => {
        if (msj.toString().includes('successfully')) Notiflix.Notify.success(msj.toString());
        else Notiflix.Notify.failure(msj.toString());
        handleCloseModalSubView();
        setNewSubView(created);
        //Create de report
    };
    const handleOpenModal = () => {
        setOpenModal(true);
        const dialog = document.getElementById('add-report');
        //@ts-expect-error
        if (dialog) dialog.showModal();
    };

    const handleOpenModalEmbedded = () => {
        setOpenModalEmbedded(true);
        const dialog = document.getElementById('pbiEmbedded');
        //@ts-expect-error
        if (dialog) dialog.showModal();
    };

    const handleOpenModalCover = () => {
        setOpenModalCover(true);
        const dialog = document.getElementById('cover-report');
        //@ts-expect-error
        if (dialog) dialog?.showModal();
    };

    const handleOpenModalSubView = () => {
        setOpenModalSubView(true);
        const dialog = document.getElementById('sub-view-report');
        //@ts-expect-error
        if (dialog) dialog?.showModal();
    };
    useEffect(() => {
        if (!isValidString(currentRoute)) {
            const route = window.location.pathname;
            updateCurrentRoueteToContext(setUserData, userData, route);
        } else {
            const route = '/' + parent + (child ? '/' + child : '');
            updateCurrentRoueteToContext(setUserData, userData, route);
        }
    }, [currentRoute, parent]);

    useEffect(() => {
        if (isValidString(parent!) && sitemap.length > 0) {
            callGetBiData();
            childIsLastLevel(parent!);
            if (!isValidString(view!) && !isValidString(child!)) {
                registerViewLog(viewInArrayViews(parent!, sitemap)?.id);
            }
            setLoaddedData(false);
        }
    }, [parent, sitemap, currentRoute, queries.t]);

    const getTreeDataAgain = async () => {
        await getTreeData(setUserData, userData);
        setUpdatedData(false);
    };
    useEffect(() => {
        if (updatedData) {
            getTreeDataAgain();
        }
    }, [updatedData]);
    useEffect(() => {
        if (newCover) {
            getCoverByType();
            setNewCover(false);
        }
    }, [newCover]);
    useEffect(() => {
        if (newSubView) {
            getTreeData(setUserData, userData);
            //getSubViewsByParentType();
            setNewSubView(false);
        }
    }, [newSubView]);

    useEffect(() => {
        if ((isValidString(view!) || isValidString(child!)) && reports!.length > 0) {
            childIsLastLevel(view! || child!);
            registerViewLog(
                isValidString(view!)
                    ? viewInArrayViews(view!, reports)?.id
                    : isValidString(child!)
                        ? viewInArrayViews(child!, reports)?.id
                        : ''
            );
        }
    }, [view, child, reports]);

    useEffect(() => {
        if (openModal && loaddedData) {
            handleOpenModal();
        } else {
            handleCloseModal();
        }
    }, [openModal]);

    useEffect(() => {
        if (openModalEmbedded && loaddedData) {
            handleOpenModalEmbedded();
        } else {
            handleCloseModalEmbedded();
        }
    }, [openModalEmbedded]);

    useEffect(() => {
        if (openModalCover) {
            handleOpenModalCover();
        } else {
            handleCloseModalCover();
        }
    }, [openModalCover]);

    const getCoverByType = async () => {
        const allCovers = await getAllViewTypesByType(parent?.replace('-', '_') || '');
        setCoverData(allCovers);
    };
    const getUsersData = async () => {
        const allUsersHolder = await getAllUsers();
        setUsersData(sortArrayByString(allUsersHolder, 'full_name'));
    };

    const getSubViewsByParentType = async () => {
        const allSubViews = getSubViewsByType(sitemap, parent?.replace('-', '_') || '');
        setSubViesData(allSubViews);
    };

    const getAllCounties = async () => {
        const allCountries = await getAllCountries();
        setCountriesData(allCountries);
        addCountriesToContext(setUserData, userData, allCountries);
        setLoadingCountries(false);
    };

    const getDivisions = async () => {
        const allDivisions = await getAllDivisions();
        setDivisionsData(allDivisions);
    };

    const getGroups = async () => {
        const allGroups = await getAllGroups();
        setGroupsData(allGroups);
    };

    const childIsLastLevel = (selecteChild: string) => {
        const dataView = viewInArrayViews(
            selecteChild,
            isValidString(view!) || isValidString(child!) ? reports : sitemap
        );
        if (dataView !== undefined && dataView !== null && dataView?.children?.length === 0) {
            setIsLastLevel(true);
            setSelectedReportsData(dataView);
        } else if (dataView !== undefined && dataView !== null) {
            setSelectedReportsData(dataView);
            if (view === undefined || dataView?.children?.length === 0) setIsLastLevel(false);
            else setIsLastLevel(true);
        } else {
            setSelectedReportsData(null);
            setIsLastLevel(false);
        }
    };

    async function callGetBiData() {
        //const reportsD = await getBiData(parent?.replace("-", "_"))
        let reportsD;
        if(parent!=="glp_dashboard") 
         reportsD= sitemap.filter((item: any) => item.name === parent)[0];
        else{
            reportsD= sitemap.filter((item: any) => item.name === "glp_v2")[0].children.filter((item: any) => item.name === parent)[0];
        }
        setReports(reportsD?.children);

        if (!isValidString(selectedCountryName!) || !isValidString(child!)) {
            // const objectOfSelectedCountry: any = countriesDataHolder?.filter((x: any) => x.id === selectedCountryID)
            setSelectedCountryName(
                reportsD?.name.includes('ecb') ? reportsD?.description : reportsD?.description
            );
            setCurrentViewData({ parent: parent, child: child, view: view, isLastLevel: false });
        }
    }
    const getAllData = async () => {
        getCoverByType();
        getSubViewsByParentType();
        getUsersData();
        getDivisions();
        getGroups();
        setLoaddedData(true);
        if (countriesData.length === 0 && loadingCountries) {
            await getAllCounties();
        }
    };

    useEffect(() => {
        if (!loaddedData) {
            setLoadingCountries(true);
            getAllData();
        }
    }, [loaddedData]);

    const getAddReportComponent = () => {
        return (
            <AddPBIReport
                className="containerModal"
                OnlyClose={handleCloseModal}
                CloseModalAndUpdate={handleCloseAndUpdateModal}
                subViewData={subViewData}
                coverData={coverData}
                divisionData={disionsData}
                groupData={groupsData}
                usersData={usersData}
                selectedReport={newReport === 'new' ? null : selectedReportsData}
                setUpdatedData={setUpdatedData}
            ></AddPBIReport>
        );
    };

    useEffect(() => {
        // console.log("useEffect selectedReportsData", selectedReportsData, "child", child)
        if (selectedReportsData) {
            if (child)
                setSelectedCountryName(
                    selectedReportsData?.name?.includes('ecb') && newReport === 'edit'
                        ? selectedReportsData?.parent_description
                        : newReport === 'edit'
                            ? selectedReportsData?.parent_description
                            : selectedReportsData?.description
                );
            else
                setSelectedCountryName(
                    selectedReportsData?.name?.includes('ecb')
                        ? selectedReportsData?.children[0]?.description
                        : selectedReportsData?.children[0]?.description
                );

            setCurrentViewData({
                parent: parent,
                child: child,
                view: view,
                isLastLevel: isLastLevel,
            });
            if (isLastLevel) {
                handleOpenModalEmbedded();
            }
            if (newReport === 'edit') {
                handleOpenModal();
            }
        }
    }, [countriesData, selectedReportsData]);

    const getAddCoverComponent = () => {
        return (
            <AddCover
                className="containerModal"
                OnlyClose={handleCloseModalCover}
                CloseModalAndUpdate={handleCloseAndUpdateModalCover}
                type={parent!}
                reportsData={sitemap}
                setNewCover={setNewCover}
            ></AddCover>
        );
    };

    const getAddSubViewComponent = () => {
        return (
            <AddSubView
                className="containerModal"
                OnlyClose={handleCloseModalSubView}
                CloseModalAndUpdate={handleCloseAndUpdateModalSubView}
                type={parent!}
                reportsData={sitemap}
                setNewSubView={setNewSubView}
                countriesData={countriesData}
            ></AddSubView>
        );
    };

    // const getEmbeddedComponent = () => {
    //     return <PbiEmbed selectedReport={selectedReportsData} />
    // }

    const handleEditReport = (report: any) => {
        setNewReport('edit');
        setSelectedReportsData(report);
        setIsModalEdit(true);
        handleOpenModal();
    };
    // const handleViewReport = (report: any) => {
    //     //setNewReport("edit");
    //     setSelectedReportsData(report);
    //     // setIsModalEdit(false);
    //     handleOpenModalEmbedded();
    // };

    const [allowButton, setAllowButton] = useState('');
    // console.log("reports", reports)
    useEffect(() => {
        // console.log(parent);
        setAllowButton(parent || '');
    });

    return (
        <div className={classNames(styles.root, className)}>
            {/* <center>GalleryReports - Current view: {(isLastLevel && view === undefined) ? child : view!}- Last level {isLastLevel ? "True" : "False"}</center> */}

            {(sitemap?.length > 0 || reports.length > 0) && countriesData?.length > 0 ? (
                <div className={`${userData.isLocal ? styles.local : ''}`}>
                    {
                        <>
                            <div className={styles.topWrapper}>
                                {isValidString(currentRoute) && (
                                    <div className={styles.tittleContainer}>
                                        <p>
                                            {currentRoute
                                                .split('/')
                                                .slice(1, 2) // Slice to get only the first result after splitting
                                                .map((_: any, index: number) => {
                                                    const description = findDescription(sitemap, _);
                                                    //console.log(description);
                                                    return (
                                                        <span
                                                            key={'route-' + index}
                                                            style={{ cursor: 'default' }}
                                                        >
                                                            {' ' +
                                                                capitalizeWords(
                                                                    description
                                                                )?.toUpperCase()}
                                                        </span>
                                                    );
                                                })}
                                        </p>
                                    </div>
                                )}
                                {!currentRoute.includes('glp_dashboard') &&
                                    <>
                                        <button
                                            className={
                                                'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center'
                                            }
                                            onClick={() => {
                                                handleOpenModalSubView();
                                            }}
                                            disabled={!userGlobals.manageReports}
                                        >
                                            Add Sub View{' '} {currentRoute}
                                        </button>
                                        <button
                                            className={
                                                'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center'
                                            }
                                            onClick={() => {
                                                handleOpenModalCover();
                                            }}
                                            disabled={!userGlobals.manageReports}
                                        >
                                            Add Cover{' '}
                                        </button>
                                        <button
                                            className={
                                                'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center'
                                            }
                                            onClick={() => {
                                                setNewReport('new');
                                                handleOpenModal();
                                            }}
                                            disabled={!userGlobals.manageReports}
                                        >
                                            Add Report{' '}
                                        </button>
                                    </>}
                                {/* {(view! || isLastLevel) &&
                                <button className={"inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center"}
                                    onClick={() => { setNewReport(null); handleOpenModal(); }} disabled={!userGlobals.manageReports}>Edit Report</button>} */}
                                {loaddedData &&
                                    groupsData!.length > 0 &&
                                    countriesData?.length > 0 && (
                                        <div>
                                            <GenericModal
                                                className="addReport"
                                                isMultiple={false}
                                                showCloseOptions={false}
                                                closeDialog={handleCloseModal}
                                                haveComponent={true}
                                                getComponent={getAddReportComponent()}
                                                name={'add-report'}
                                            ></GenericModal>
                                            <GenericModal
                                                className="addCover"
                                                isMultiple={false}
                                                showCloseOptions={false}
                                                closeDialog={handleCloseModalCover}
                                                haveComponent={true}
                                                getComponent={getAddCoverComponent()}
                                                name={'cover-report'}
                                            ></GenericModal>
                                            <GenericModal
                                                className="addSubView"
                                                isMultiple={false}
                                                showCloseOptions={false}
                                                closeDialog={handleCloseModalSubView}
                                                haveComponent={true}
                                                getComponent={getAddSubViewComponent()}
                                                name={'sub-view-report'}
                                            ></GenericModal>
                                        </div>
                                    )}
                            </div>
                            <Tabs
                                selectedCountryName={
                                    selectedCountryName ||
                                    reports[0]?.description ||
                                    reports[0]?.description
                                }
                                firstChildrenData={reports}
                                currentViewData={currentViewData}
                                setUserData={setUserData}
                                userData={userData}
                                canEdit={userGlobals.manageReports}
                                handleEditReport={handleEditReport}
                                setUpdatedData={setUpdatedData}
                            />
                        </>
                    }
                    {/* <div>
                    {selectedReportsData &&
                        <div><GenericModal className='pbiEmbedded' isMultiple={false} showCloseOptions={false} closeDialog={handleCloseModalEmbedded} haveComponent={true} getComponent={getEmbeddedComponent()} name={"pbiEmbedded"}></GenericModal></div>}
                </div> */}
                </div>
            ) : (
                <div className={styles.spinner}>
                    <Spinner />
                </div>
            )}
        </div>
    );
};
