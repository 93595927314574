import styles from './slicer-item.module.scss';
import classNames from 'classnames';

export interface SlicerItemProps {
    className?: string;
    title: string;
    check: boolean;

    click: (v: any) => void;
    prop?: object;
    active: boolean;
    compactMode?: boolean;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const SlicerItem = ({
    className,
    title,
    check,

    click,
    prop,
    active,
    compactMode=false,
}: SlicerItemProps) => {
    return (
            <button
                className={classNames(styles.root, className) + ' rounded text-xs ' + (compactMode ? ' text-center' : ' text-left py-0.5')}
                style={{
                    backgroundColor: check && active ? '#BEFFEC' : !active ? '#f3f0f0' : 'transparent',
                    border: check ? 'none' : '1px #acacac solid',
                    cursor: 'pointer',
                    minWidth: compactMode  ? '15%' : '100%',
                    marginLeft: compactMode ? '3px' : '',
                }}
                onClick={() => click(prop)}
                disabled={!active}
            >
                {title}
            </button>
    );
};
